import { configureStore } from '@reduxjs/toolkit'
import sesionReducer from '../feactures/SesionSlice'
import { usuarioAPI } from '../services/usuario'
import { rolAPI } from '../services/rol'
import { multimediaAPI } from '../services/multimedia'
import { remisionAPI } from '../services/remision'
import { productoAPI } from '../services/producto'
import { clienteAPI } from '../services/cliente'
import { vendedorAPI } from '../services/vendedor'
import { categoriaAPI } from '../services/categoria'
import { ciudadAPI } from '../services/ciudad'
import { marcaAPI } from '../services/marca'
import { pedidoAPI } from '../services/pedido'
import { remisionPublicaAPI } from '../services/remision_publica'
import { creditoAPI } from '../services/credito'

export const store = configureStore({
    reducer: {
        sesionSlice: sesionReducer,
        [usuarioAPI.reducerPath]: usuarioAPI.reducer,
        [rolAPI.reducerPath]: rolAPI.reducer,
        [multimediaAPI.reducerPath]: multimediaAPI.reducer,
        [remisionAPI.reducerPath]: remisionAPI.reducer,
        [productoAPI.reducerPath]: productoAPI.reducer,
        [clienteAPI.reducerPath]: clienteAPI.reducer,
        [vendedorAPI.reducerPath]: vendedorAPI.reducer,
        [categoriaAPI.reducerPath]: categoriaAPI.reducer,
        [ciudadAPI.reducerPath]: ciudadAPI.reducer,
        [marcaAPI.reducerPath]: marcaAPI.reducer,
        [pedidoAPI.reducerPath]: pedidoAPI.reducer,
        [remisionPublicaAPI.reducerPath]: remisionPublicaAPI.reducer,
        [creditoAPI.reducerPath]: creditoAPI.reducer,
    },
    middleware: (getDefaultMiddleware) => [
        ...getDefaultMiddleware({serializableCheck: false}),
        usuarioAPI.middleware,
        rolAPI.middleware,
        multimediaAPI.middleware,
        remisionAPI.middleware,
        productoAPI.middleware,
        clienteAPI.middleware,
        vendedorAPI.middleware,
        categoriaAPI.middleware,
        ciudadAPI.middleware,
        marcaAPI.middleware,
        pedidoAPI.middleware,
        remisionPublicaAPI.middleware,
        creditoAPI.middleware,
    ],
});