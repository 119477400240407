import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../config/config'
import { EkQuery } from './query'
import { token } from 'account-react-eurekasigma'
import { ROL_CLIENTE } from '../js/rol'

const TABLA_CLIENTE = 'EurekaCrRol'
const TABLA_CLIENTE_FORM = 'EurekaAcUsuario'
const TABLA_DOMICILIO = 'EurekaInDomicilio'

export const clienteAPI = createApi({
    reducerPath: 'clienteAPI',
    baseQuery: fetchBaseQuery({ baseUrl: config.urlCRUFDEK }),
    tagTypes: ['Clientes'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerClientes: builder.query({
            query: () => {
                return {
                    url: `/READ?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_CLIENTE)
                                .where('nombre', ROL_CLIENTE.nombre)
                                .with('eureka_cr_rol_usuarios.eureka_ac_usuario.eureka_pa_cuenta_fiscals')
                                .with('eureka_cr_rol_usuarios.eureka_ac_usuario.eureka_cr_rol_usuarios')
                                .with('eureka_cr_rol_usuarios.eureka_ac_usuario.eureka_cr_multimedia')
                                .with('eureka_cr_rol_usuarios.eureka_ac_usuario.eureka_st_transacciones.eureka_st_transacciones_conceptos')
                                .with('eureka_cr_rol_usuarios.eureka_ac_usuario.eureka_st_transacciones.eureka_st_transacciones_detalles')
                                .with('eureka_cr_rol_usuarios.eureka_ac_usuario.eureka_st_transacciones.eureka_en_guias')
                                .with('eureka_cr_rol_usuarios.eureka_ac_usuario.eureka_pa_tarjetas')
                                .with('eureka_cr_rol_usuarios.eureka_ac_usuario.eureka_in_domicilios')
                                .with('eureka_cr_rol_usuarios.eureka_ac_usuario.eureka_pa_billeteras')
                                .first()
                }
            },
            transformResponse: (data) => {
                let clientes = data?.eureka_cr_rol_usuarios
                clientes = clientes.filter(cliente => cliente?.eureka_ac_usuario?.estatus !== 'bloqueado')
                return clientes.map(cliente => cliente?.eureka_ac_usuario)
            }
        }),
        crearCliente: builder.mutation({
            /**
             * @param {{ id: number, body: object }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_CLIENTE_FORM)
                body = (data?.id) ? body.where('id', data.id).update(data.body) : body.create(data.body)

                return {
                    url: `/CUFD?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        crearDomicilio: builder.mutation({
            /**
             * @param {{ id: number, body: object }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_DOMICILIO)
                body = (data?.id) ? body.where('id', data.id).update(data.body) : body.create(data.body)

                return {
                    url: `/CUFD?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        obtenerCliente: builder.query({
            /**
             * @param {{ id: number }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_CLIENTE_FORM)
                body = body.where('id', data?.id)

                return {
                    url: `/READ?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body.with('eureka_pa_cuenta_fiscals')
                              .with('eureka_cr_rol_usuarios')
                              .with('eureka_cr_multimedia')
                              .with('eureka_st_transacciones.eureka_st_transacciones_conceptos')
                              .with('eureka_st_transacciones.eureka_st_transacciones_detalles')
                              .with('eureka_st_transacciones.eureka_en_guias')
                              .with('eureka_pa_tarjetas')
                              .with('eureka_in_domicilios')
                              .with('eureka_pa_billeteras')
                              .first()
                }
            },
        }),
    }),
})


export const { 
    useObtenerClientesQuery,
    useCrearClienteMutation,
    useCrearDomicilioMutation,
    useObtenerClienteQuery,
} = clienteAPI