import Columna, { TAM_SM } from "../Columna/Columna"
import Fila from "../Fila/Fila"
import { numberFormat } from "./adaptar_columna"

const obtenerValor = (dato = null, estructura = null) => {
    let valor = ''
    if(dato && estructura) {
        if(estructura?.text) {
            valor = estructura.text(dato)
        }
        else if(estructura?.tipo === 'texto' || estructura?.tipo === 'text') {
            valor = String(dato[estructura?.col ?? ''] ?? '')
        }
        else if(estructura?.tipo === 'numero' || estructura?.tipo === 'number') {
            valor = parseInt(dato[estructura?.col ?? ''] ?? 0)
        }
        else if(estructura?.tipo === 'precio') {
            valor = numberFormat(parseFloat(dato[estructura?.col ?? '']))
        }
        else {
            valor = String(dato[estructura?.col ?? ''] ?? '')
        }
        return valor
    }
    else return valor
}

export const adaptarDatos = (data = [], estructura = [], modo = 'form', handlerCol = () => {}) => {
    const filas = data.map((concepto, i) => {
        return (
            <Fila key={i} agregarFondo={((i + 1) % 2) === 0}>
                {
                    estructura.map((estructura, j) => {
                        return (
                            <Columna 
                                key={j}
                                tipo="normal"
                                tam={estructura?.tam ?? TAM_SM}
                                valor={obtenerValor(concepto, estructura)}
                                incrementable={estructura?.incrementable && modo === 'form'}
                                onChange={
                                    modo === 'form' ? 
                                        (val, tipo) => { handlerCol(i, concepto, estructura, tipo, val) } : 
                                        () => {}
                                }
                            />
                        )
                    })
                }
            </Fila>
        )
    })
    return filas
} 