import CryptoJS from 'crypto-js'
import { config } from '../../config/config'
import { useObtenerRemisionQuery } from '../../services/remision_publica'
import { CargandoPagina, Theme } from 'eureka-design'
import DetalleRemision from './detalle-remision'

const DetalleRemisionCliente = () => {
    const param = new URLSearchParams(window.location.search)
    const remisionId = parseInt(CryptoJS.AES.decrypt(param?.get('id') ?? '', config.contraseniaApp).toString(CryptoJS.enc.Utf8))

    const { data:remision, isLoading } = useObtenerRemisionQuery({ id: remisionId })

    if(isLoading){
        return (
            <CargandoPagina 
                mensaje="Cargando, por favor espere..." 
                colorPrincipal={config.colorPrimario} 
            />
        )
    }
    else {
        return (
            <Theme
                darkColor={config.colorSecundario}
                lightColor={config.colorPrimario}
                compareDark={config.colorPrimario}
            >   
                <div data-theme="light">
                    <DetalleRemision 
                        remision={remision}
                        onClose={null}
                        modoVista={true}
                    />
                </div>
            </Theme>
        )
    }
}

export default DetalleRemisionCliente