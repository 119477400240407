import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../config/config'
import { EkQuery } from './query'
import { token } from 'account-react-eurekasigma'
import { ROL_VENDEDOR } from '../js/rol'

const TABLA_VENDEDOR = 'EurekaCrRol'
const TABLA_VENDEDOR_FORM = 'EurekaAcUsuario'
const TABLA_DOMICILIO = 'EurekaInDomicilio'

export const vendedorAPI = createApi({
    reducerPath: 'vendedorAPI',
    baseQuery: fetchBaseQuery({ baseUrl: config.urlCRUFDEK }),
    tagTypes: ['Vendedores'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerVendedores: builder.query({
            query: () => {
                return {
                    url: `/READ?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: new EkQuery(TABLA_VENDEDOR)
                                .where('nombre', ROL_VENDEDOR.nombre)
                                .with('eureka_cr_rol_usuarios.eureka_ac_usuario.eureka_pa_cuenta_fiscals')
                                .with('eureka_cr_rol_usuarios.eureka_ac_usuario.eureka_cr_rol_usuarios')
                                .with('eureka_cr_rol_usuarios.eureka_ac_usuario.eureka_cr_multimedia')
                                .with('eureka_cr_rol_usuarios.eureka_ac_usuario.eureka_st_transacciones.eureka_st_transacciones_conceptos')
                                .with('eureka_cr_rol_usuarios.eureka_ac_usuario.eureka_st_transacciones.eureka_st_transacciones_detalles')
                                .with('eureka_cr_rol_usuarios.eureka_ac_usuario.eureka_st_transacciones.eureka_bs_cliente.eureka_pa_billeteras')
                                .with('eureka_cr_rol_usuarios.eureka_ac_usuario.eureka_pa_tarjetas')
                                .with('eureka_cr_rol_usuarios.eureka_ac_usuario.eureka_in_domicilios')
                                .first()
                }
            },
            transformResponse: (data) => {
                let vendedores = data?.eureka_cr_rol_usuarios

                vendedores = vendedores.filter(vendedor => vendedor?.eureka_ac_usuario?.estatus !== 'bloqueado')
                return vendedores.map(vendedor => vendedor?.eureka_ac_usuario)
            }
        }),
        crearVendedor: builder.mutation({
            /**
             * @param {{ id: number, body: object }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_VENDEDOR_FORM)
                body = (data?.id) ? body.where('id', data.id).update(data.body) : body.create(data.body)

                return {
                    url: `/CUFD?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        crearDomicilio: builder.mutation({
            /**
             * @param {{ id: number, body: object }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_DOMICILIO)
                body = (data?.id) ? body.where('id', data.id).update(data.body) : body.create(data.body)

                return {
                    url: `/CUFD?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
    }),
})


export const { 
    useObtenerVendedoresQuery,
    useCrearVendedorMutation,
    useCrearDomicilioMutation,
} = vendedorAPI