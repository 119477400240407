import './ContenedorScroll.css'

const ContenedorScroll = ({ children, tipo = 'md' }) => {
    return (
        <div className={`contenedor-scroll contenedor-scroll--${tipo}`}>
            { children }
        </div>
    )
}

export default ContenedorScroll