
export const numberFormat = (number = 0.0, decimals = 2, decPoint = '.', thousandsSep = ',', dollarSimb = '$') => {
    number = number ? number : 0
    number = Number(number).toFixed(decimals)
    let numberStr = String(number)
    let posDec = numberStr.indexOf('.')
    let numberIntStr = numberStr.slice(0, posDec)
    let decimalsStr = numberStr.slice(posDec).replace('.', decPoint)
    let digitsCount = 0
    let numberFormat = []


    for(let i = numberIntStr.length-1; i >= 0; i--) {
        if(digitsCount === 3) {
            digitsCount = 0
            numberFormat.push(thousandsSep)
        }
        
        digitsCount++
        numberFormat.push(numberIntStr[i])
    }
    numberStr = numberFormat.reverse().join('') + decimalsStr

    if(dollarSimb) {
        return `${dollarSimb}${numberStr}`
    }
    else {
        return numberStr
    }
}