import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../config/config'
import { EkQuery } from './query'
import { token } from 'account-react-eurekasigma'

const TABLA_CIUDAD = 'EurekaInCiudade'

export const ciudadAPI = createApi({
    reducerPath: 'ciudadAPI',
    baseQuery: fetchBaseQuery({ baseUrl: config.urlCRUFDEK }),
    tagTypes: ['Ciudades'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerCiudades: builder.query({
            query: () => {
                let body = new EkQuery(TABLA_CIUDAD)

                return {
                    url: `/READ?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body.get()
                }
            },
        }),
    })
})


export const { 
    useObtenerCiudadesQuery,
} = ciudadAPI