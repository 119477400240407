import './Membrete.css'

const Membrete = ({
    logo = '',
    nombre = 'SERVI CARNES DE OCCIDENTE, S.A. DE C.V',
    direccion = 'CALLE 7 N° 615 ZONA INDUSTRIAL',
    telefono = '(CONMUTADOR TEL. 31-45-07-23)',
    ciudad = 'GUADALAJARA, JAL. C.P.44940',
    rfc = 'R.F.C. SCO-960426-CFA',
    extra = 'PRO. 9',
    tam = 'md',
}) => {
    return (
        <>
            <div className="membrete">
                <img className="membrete__logo" src={logo} alt="logo" />
                <div className="membrete__informacion">
                    <p className={`membrete__informacion__titulo membrete__informacion__sub-titulo--${tam}`}><b>{nombre}</b></p>
                    <p className={`membrete__informacion__sub-titulo membrete__informacion__sub-titulo--${tam}`}>{direccion}</p>
                    <p className={`membrete__informacion__sub-titulo membrete__informacion__sub-titulo--${tam}`}>{telefono}</p>
                    <p className={`membrete__informacion__sub-titulo membrete__informacion__sub-titulo--${tam}`}>{ciudad}</p>
                    <p className={`membrete__informacion__sub-titulo membrete__informacion__sub-titulo--${tam}`}><b>{rfc}</b></p>
                </div>
            </div>
            <div className="extra-membrete">
                <p className={`extra-membrete__info extra-membrete__info--${tam}`}><b>{extra}</b></p>
            </div>
        </>
    )
}

export default Membrete