import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../config/config'
import { EkQuery } from './query'
import { token } from 'account-react-eurekasigma'

const TABLA_REMISION = 'EurekaStTransaccione'
const TABLA_CONCEPTO = 'EurekaStTransaccionesConcepto'
export const TABLA_DETALLES = 'EurekaStTransaccionesDetalle'
export const TIPO_REMISION = 'remision'

export const remisionAPI = createApi({
    reducerPath: 'remisionAPI',
    baseQuery: fetchBaseQuery({ baseUrl: config.urlCRUFDEK }),
    tagTypes: ['Remisiones'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerRemisiones: builder.query({
            /**
             * @param {{ estatus: string }} data 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_REMISION)
                body = (data?.estatus) ? body.where('cxp_cxc', data.estatus) : body
                body = (data?.tipo) ? body.where('tipo', data.tipo) : body

                return {
                    url: `/READ?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body.with('eureka_st_transacciones_conceptos')
                              .with('eureka_st_transacciones_detalles')
                              .with('eureka_ac_usuario')
                              .with('eureka_bs_cliente.eureka_pa_billeteras')
                              .with('eureka_fa_factura')
                              .with('eureka_en_guia')
                              .get()
                }
            },
        }),
        crearRemision: builder.mutation({
            /**
             * @param {{ id: number, body: object }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_REMISION)
                body = (data?.id) ? body.where('id', data.id).update(data.body) : body.create(data.body)

                return {
                    url: `/CUFD?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        crearConceptos: builder.mutation({
            /**
             * @param {{ id: number, body: object }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_CONCEPTO)
                body = (data?.id) ? body.where('id', data.id).update(data.body) : body.create(data.body)

                return {
                    url: `/CUFD?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        crearDetalles: builder.mutation({
            /**
             * @param {{ id: number, body: object }} data 
             * @returns 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_DETALLES)
                body = (data?.id) ? body.where('id', data.id).update(data.body) : body.create(data.body)

                return {
                    url: `/CUFD?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        obtenerRemision: builder.query({
            /**
             * @param {{ id: number }} data 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_REMISION).where('id', data?.id)

                return {
                    url: `/READ?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body.with('eureka_st_transacciones_conceptos')
                              .with('eureka_st_transacciones_detalles')
                              .with('eureka_ac_usuario')
                              .with('eureka_bs_cliente.eureka_pa_billeteras')
                              .with('eureka_fa_factura')
                              .with('eureka_en_guia')
                              .first()
                }
            },
        }),
        obtenerRemisionesCliente: builder.query({
            /**
             * @param {{ clienteId: number }} data 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_REMISION).where('cliente', data?.clienteId)

                return {
                    url: `/READ?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body.with('eureka_st_transacciones_conceptos')
                              .with('eureka_st_transacciones_detalles')
                              .with('eureka_ac_usuario')
                              .with('eureka_fa_factura')
                              .with('eureka_en_guia')
                              .get()
                }
            },
        }),
    })
})


export const { 
    useObtenerRemisionesQuery,
    useCrearRemisionMutation,
    useCrearConceptosMutation,
    useCrearDetallesMutation,
    useObtenerRemisionesClienteQuery,
} = remisionAPI