import { Tabla, buildData, ChipEstatus, Miniatura } from 'eureka-design'
import { ESTATUS_ACTIVO, ESTATUS_INACTIVO, numberFormat, obtenerData, obtenerLogo } from '../../js/util'
import { useEffect, useState } from 'react'
import InfoCliente from './info-cliente'
import { useCrearClienteMutation, useObtenerClientesQuery } from '../../services/cliente'
import FormCliente, { NOM_FORM_CLIENTE } from './form-cliente'
import FormCredito from './form-credito'

const estiloDivNombre = { 
    display: 'flex', 
    alignItems: 'center',
    gap: '5px'
}

const estiloSpanNombre = {
    width: '80%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
}

const ListaClientes = ({ Push, ShowAlert, UpProps, form_cliente }) => {
    const { data, isLoading, refetch:actualizarClientes } = useObtenerClientesQuery()

    /** EDITAR */
    const [crearCliente] = useCrearClienteMutation()

    /** FILTRO */
    const [total, fijaTotal] = useState(0)
    const [totalActivos, fijaTotalActivos] = useState(0)
    const [totalInactivos, fijaTotalInactivos] = useState(0)

    /** MODAL */
    const [cliente, fjiaCliente] = useState(form_cliente)
    const [mostrarModal, cambiarEstadoModal] = useState(false)
    const [mostrarModalDetalles, cambiarEstadoModalDetalles] = useState(false)
    const [mostrarModalCredito, cambiarEstadoModalCredito] = useState(false)
    const [titulo, fijaTitulo] = useState('Agregar un nuevo cliente')

    useEffect(() => {
        if(!isLoading) {
            fijaTotal(data.length)
            fijaTotalActivos(data.filter((d) => d.estatus === 'activo').length)
            fijaTotalInactivos(data.filter((d) => d.estatus === 'inactivo').length)
        }
        // else esperar los datos

        fjiaCliente(form_cliente)
    }, [data, isLoading, form_cliente])

    const handlerRegistrar = () => {
        console.log('agregar')
        UpProps({ [NOM_FORM_CLIENTE]: null })
        fjiaCliente(null)
        fijaTitulo('Agregar un nuevo cliente')
        cambiarEstadoModal(true)
    }

    const handlerEditar = (item) => {
        let cliente = item.item
        let domicilio = obtenerData(cliente, 'eureka_in_domicilios')
        let foto = obtenerLogo({ carpeta: 'cliente', nombre: cliente?.foto })

        fjiaCliente({
            ...cliente,
            domicilio_id: domicilio?.id,
            domicilio: domicilio,
            foto: foto,
        })
        fijaTitulo(`Editar información de ${cliente?.nombre}`)
        cambiarEstadoModal(true)
    }

    const handlerArchivar = (item) => {
        let cliente = item.item
        let estatus = ''
        let mensaje = ''

        if(cliente?.estatus === ESTATUS_ACTIVO) {
            estatus = ESTATUS_INACTIVO
            mensaje = 'El cliente a pasado a inactivo'
        }
        else {
            estatus = ESTATUS_ACTIVO
            mensaje = 'El cliente a pasado a activo'
        }

        crearCliente({
            id: cliente.id,
            body: { estatus: estatus }
        })
        .unwrap()
        .then(() => {
            ShowAlert({
                icono: 'success',
                titulo: 'Estatus Actualizado',
                mensaje: mensaje,
                time: 3,
            })
            actualizarClientes()
        })
    }

    const handlerVerRemisiones = (item) => {
        let cliente = item.item
        Push('lista_remisiones_usuario', {
            usuario: cliente,
            tipo: 'cliente',
        })
    }

    const handlerVerDetalles = (item) => {
        let cliente = item.item
        let domicilio = obtenerData(cliente, 'eureka_in_domicilios')
        let foto = obtenerLogo({ carpeta: 'cliente', nombre: cliente?.foto })
        // console.log(cliente)
        
        fjiaCliente({
            ...cliente,
            domicilio_id: domicilio?.id,
            foto_preview: foto,
            domicilio: domicilio
        })
        cambiarEstadoModalDetalles(true)
    }

    const handlerVerMapa = () => {
        Push('mapa_clientes')
    }

    const handlerOtorgarCredito = (item) => {
        const cliente = item.item
        // console.log(cliente)

        fjiaCliente(cliente)
        cambiarEstadoModalCredito(true)
    }

    if(isLoading) return
    else {
        return (
            <>
                <FormCliente 
                    cliente={cliente}
                    titulo={titulo}
                    mostrar={mostrarModal}
                    handlerMostrar={cambiarEstadoModal}
                    actualizarClientes={actualizarClientes}
                    UpProps={UpProps}
                />

                <InfoCliente 
                    cliente={cliente}
                    mostrar={mostrarModalDetalles} 
                    onClose={() => {
                        cambiarEstadoModalDetalles(false)
                    }}
                />

                <FormCredito 
                    mostrar={mostrarModalCredito}
                    cambiarEstado={cambiarEstadoModalCredito}
                    actualizar={actualizarClientes}
                    cliente={cliente}
                />

                <Tabla
                    checkbox={false}
                    rowSize="medium"
                    noData="Aún no hay clientes registrados"
                    busqueda={{ placeholder: "Buscar cliente", keys: ["nombre"] }}
                    keys={[
                        {
                            icono: 'fa-users',
                            text: "Todos",
                            detalle: total,
                            filtro: () => true
                        },
                        {
                            icono: 'fa-circle-check',
                            text: "Activos",
                            detalle: totalActivos,
                            filtro: item => item.item.estatus === "activo"
                        },
                        {
                            icono: 'fa-user-slash',
                            text: "Archivados",
                            detalle: totalInactivos,
                            filtro: item => item.item.estatus === "inactivo"
                        },
                    ]}
                    headers={[
                        { key: 'nombre',    text: 'Nombre',             type: 'text' },
                        { key: 'correo',    text: 'Correo eléctronico', type: 'text' },
                        { key: 'telefono',  text: 'Teléfono',           type: 'text' },
                        { key: 'municipio', text: 'Municipio',          type: 'text' },
                        { key: 'credito',   text: 'Crédito Disponible', type: 'text' },
                        { key: 'estatus',   text: 'Estado',             type: 'text' },
                    ]}
                    data={buildData(data, [
                        { 
                            key: 'nombre',
                            type: 'text',
                            text: (item) => {
                                let foto = (item?.foto?.length > 0) ? { carpeta: 'cliente', nombre: item?.foto } : null
                                return (
                                    <div style={estiloDivNombre}>
                                        <div style={{ width: '30px' }}>
                                            <Miniatura avatar={true} src={obtenerLogo(foto)} />
                                        </div>
                                        <span style={estiloSpanNombre}>{item.nombre}</span>
                                    </div>
                                )
                            }
                        },
                        { key: 'correo',    type: 'text', text: (item) => item?.correo ?? 'Sin correo' },
                        { key: 'telefono',  type: 'text', text: (item) => item?.telefono ?? 'Sin teléfono' },
                        { 
                            key: 'municipio', 
                            type: 'text',
                            text: (item) => {
                                let domicilio = obtenerData(item, 'eureka_in_domicilios')
                                return domicilio?.nombre ?? 'N/A'
                            }
                        },
                        { key: 'credito', type: 'text', text: (item) => numberFormat(item?.eureka_pa_billeteras[0]?.puntos) },
                        { 
                            key: 'estatus',
                            type: 'text',
                            text: (item) => {
                                let texto, tipo
                                if(item.estatus === ESTATUS_ACTIVO) {
                                    texto = 'Activo'
                                    tipo = 'Aceptado'
                                }
                                else {
                                    texto = 'Cancelado'
                                    tipo = 'Error'
                                }
                                return <ChipEstatus texto={texto} tipo={tipo} />
                            }
                        },
                    ])}
                    botonesHead={[
                        { icono: 'fa-location-dot', tooltip: 'Ver mapa', onClick: handlerVerMapa },
                        { icono: 'fa-plus', tooltip: 'Agregar', onClick: handlerRegistrar },
                    ]}
                    botonesRow={[
                        { icono: 'fa-credit-card', tooltip: 'Otorgar crédito', onClick: handlerOtorgarCredito },
                        { icono: 'fa-file-invoice-dollar', tooltip: 'Ver remisiones', onClick: handlerVerRemisiones },
                        { icono: 'fa-file-pen', tooltip: 'Editar', onClick: handlerEditar },
                        { icono: 'fa-circle-xmark', tooltip: 'Cancelar', onClick: handlerArchivar },
                    ]}
                    onClickRow={handlerVerDetalles}
                />
            </>
        )
    }
}

export default ListaClientes