import { ModalForm, ImageInput, Input } from 'eureka-design'
import ContenedorScroll from '../../components/ContenedorScroll/ContenedorScroll'
import { useCrearVendedorMutation, useCrearDomicilioMutation } from '../../services/vendedor'
import { useEffect, useMemo, useState } from 'react'
import { useCrearMultimediaMutation } from '../../services/multimedia'
import { useDarRolMutation } from '../../services/rol'
import { ROL_VENDEDOR } from '../../js/rol'

export const NOM_FORM_VENDEDOR = 'form_vendedor'

const FormVendedor = ({
    vendedor = null,
    mostrar = true,
    handlerMostrar = () => {},
    actualizarVendedores = () => {},
    UpProps = () => {},
    titulo = 'Agregar un nuevo vendedor',
}) => {
    const form_vendedor = useMemo(() => vendedor, [vendedor])

    const [crearMultimedia, {isLoading:actualizandoFoto, isError:fotoErronea, isSuccess:fotoActualizada}] = useCrearMultimediaMutation()
    const [crearVendedor, {isLoading:actVendedor, isError:vendedorError, isSuccess:vendedorCorrecto}] = useCrearVendedorMutation()
    const [crearDomicilio, {isLoading:actualizandoDomicilio, isError:domicilioErronea, isSuccess:domicilioActualizado}] = useCrearDomicilioMutation()
    const [darRol] = useDarRolMutation()

    const [vendedorId, fijaVendedorId] = useState(form_vendedor?.id)
    const [domicilioId, fijaDomicilioId] = useState(form_vendedor?.domicilio?.id)

    useEffect(() => {
        fijaVendedorId(form_vendedor?.id)
        fijaDomicilioId(form_vendedor?.domicilio?.id)
    }, [form_vendedor])

    const handlerFoto = async (e) => {
        let vendedorID = vendedorId ? vendedorId : await handlerVendedor('nombre', '')
        let archivo = e.target.files[0]

        handlerProps('foto', archivo)
        return await crearMultimedia({
            tabla: 'EurekaAcUsuario',
            media: {
                id: vendedorID,
                file: archivo,
                carpeta: 'vendedor',
                col: 'foto',
            }
        })
        .unwrap()
        .then((res) => {
            let id = res?.mensaje?.id

            if(id) {
                fijaVendedorId(id)
                return id
            }
            else {
                return vendedorId
            }
        })
    }

    const handlerVendedor = async (nom, val) => {
        return await crearVendedor({
            id: vendedorId,
            body: {
                [nom]: val,
            }
        })
        .unwrap()
        .then((res) => {
            let id = res?.mensaje?.id

            if(id) {
                fijaVendedorId(id)
                darRol({
                    body: {
                        usuario: id,
                        rol: ROL_VENDEDOR.id
                    }
                })
                return id
            }
            else {
                return vendedorId
            }
        })
    }

    const handlerDomicilio = async (nom, val) => {
        let vendedorID = vendedorId ? vendedorId : await handlerVendedor('nombre', '')

        return await crearDomicilio({
            id: domicilioId,
            body: {
                [nom]: val,
                usuario: vendedorID,
                tipo: 'principal',
                estatus: 'activo',
            }
        })
        .unwrap()
        .then((res) => {
            let domicilioID = res?.mensaje?.id

            if(domicilioID) {
                fijaDomicilioId(domicilioID)
                return domicilioID
            }
            else {
                return domicilioId
            }
        })
    }

    const handlerInputCliente = (val, nom) => {
        handlerVendedor(nom, val)
        handlerProps(nom, val)
    }

    const handlerInputDomicilio = (val, nom) => {
        handlerDomicilio(nom, val)
        handlerProps('domicilio', {
            ...(form_vendedor?.domicilio ? form_vendedor.domicilio : {}),
            [nom]: val
        })
    }

    const handlerProps = (nom, val) => {
        UpProps({
            [NOM_FORM_VENDEDOR]: {
                ...form_vendedor,
                id: vendedorId,
                domicilio_id: domicilioId,
                [nom]: val,
            }
        })
    }

    return (
        <ModalForm 
            show={mostrar}
            onClose={() => {
                actualizarVendedores()
                handlerMostrar(false)
            }}
            titulo={titulo}
            isLoading={[actVendedor, actualizandoFoto, actualizandoDomicilio]}
            isError={[vendedorError, fotoErronea, domicilioErronea]}
            isSuccess={[vendedorCorrecto, fotoActualizada, domicilioActualizado]}
        >
            <ContenedorScroll>
                {
                    mostrar ? <ImageInput 
                        width={250}
                        height={250}
                        preview={form_vendedor?.foto}
                        changeFunction={handlerFoto}
                    /> : undefined
                }
                <Input 
                    iconLeft="fa-user"
                    placeholder="Nombre"
                    nombre="nombre"
                    changeFunction={handlerInputCliente}
                    value={form_vendedor?.nombre ?? ''}
                    isLoading={actVendedor}
                />
                <Input 
                    iconLeft="fa-envelope"
                    placeholder="Correo eléctronico"
                    nombre="correo"
                    changeFunction={handlerInputCliente}
                    value={form_vendedor?.correo ?? ''}
                    isLoading={actVendedor}
                />
                <Input 
                    iconLeft="fa-phone"
                    placeholder="Teléfono"
                    nombre="telefono"
                    changeFunction={handlerInputCliente}
                    value={form_vendedor?.telefono ?? ''}
                    isLoading={actVendedor}
                />
                <h4>Dirección</h4>
                <div style={{ display: 'grid', gap: '8px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <Input 
                            placeholder="Calle"
                            nombre="calle"
                            changeFunction={handlerInputDomicilio}
                            value={form_vendedor?.domicilio?.calle ?? ''}
                            isLoading={actualizandoDomicilio}
                        />
                        <div>
                            <Input
                                placeholder="N° exterior"
                                nombre="numero"
                                changeFunction={handlerInputDomicilio}
                                value={form_vendedor?.domicilio?.numero ?? ''}
                                isLoading={actualizandoDomicilio}
                            />
                        </div>
                        <div>
                            <Input
                                placeholder="N° interior"
                                nombre="interior"
                                changeFunction={handlerInputDomicilio}
                                value={form_vendedor?.domicilio?.interior ?? ''}
                                isLoading={actualizandoDomicilio}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <div style={{ width: '57%' }}>
                            <Input 
                                placeholder="Código postal"
                                nombre="referencias"
                                changeFunction={handlerInputDomicilio}
                                value={form_vendedor?.domicilio?.referencias ?? ''}
                                isLoading={actualizandoDomicilio}
                            />
                        </div>
                        <Input
                            placeholder="Municipio"
                            nombre="nombre"
                            changeFunction={handlerInputDomicilio}
                            value={form_vendedor?.domicilio?.nombre ?? ''}
                            isLoading={actualizandoDomicilio}
                        />
                        <Input
                            placeholder="Colonia"
                            nombre="entrecalles"
                            changeFunction={handlerInputDomicilio}
                            value={form_vendedor?.domicilio?.entrecalles ?? ''}
                            isLoading={actualizandoDomicilio}
                        />
                    </div>
                </div>
            </ContenedorScroll>
        </ModalForm>
    )
}

export default FormVendedor