export const NOM_CLIENTE = '[nombre_cliente]'
export const NOM_ENLACE = '[enlace_pedido]'
export const MENSAJE_WHATSAPP = `¡Hola ${NOM_CLIENTE}! Tu pedido ha sido confirmado exitosamente. Puedes ver los detalles y el estado de tu pedido aquí: ${NOM_ENLACE}. ¡Gracias por elegirnos!`

export const PAGADA = 'liquidado'
export const PENDIENTE_PAGO = 'cobrar'
export const SIN_PAGAR = 'pagar'

export const calcularTotal = (remisiones = [], fecha = null) => {
    let total = 0
    if(fecha) {
        remisiones.forEach((remision) => {
            let fechaRemision = new Date(remision.fecha)
            if(remision.estatus === PAGADA) {
                if(fecha.getFullYear() === fechaRemision.getFullYear() && fecha.getMonth() === fechaRemision.getMonth()) {
                    total += parseFloat(remision.total)
                }
                else total += 0
            }
            else total += 0
        })
    }
    else {
        remisiones.forEach((remision) => {
            if(remision.estatus === PAGADA) total += parseFloat(remision.total)
            else total += 0
        })
    }
    return total
}

export const calcularTotalConceptos = (conceptos = []) => {
    let total = 0
    conceptos.forEach((concepto) => {
        total += parseFloat(concepto.costo) * parseFloat(concepto.cantidad)
    })
    return total
}

export const calcularKilos = (conceptos = []) => {
    let total = 0
    conceptos.forEach((concepto) => {
        total += parseFloat(concepto.cantidad)
    })
    return total
}