import './InformacionVenta.css'
import Membrete from "../Membrete/Membrete"
import logo from '../../imgs/icono.svg'
import { Input } from 'eureka-design'
import CardDetalle from '../CardDetalle/CardDetalle'
import { numberFormat } from '../../js/util'
import { useObtenerClienteQuery } from '../../services/cliente'
import TablaConceptos from '../TablaConceptos/TablaConceptos'
import { calcularTotalConceptos } from '../../js/remision'

const InformacionVenta = ({ venta = {}, conceptos = [], modoVista = false }) => {
    const { data:cliente, isLoading } = useObtenerClienteQuery({ id: venta?.cliente })

    if(isLoading) return
    else {
        return (
            <div className="contenedor-informacion-venta">
                <Membrete 
                    logo={logo}
                    tam={modoVista ? "md" : "sm"}
                />
                <div className="contenedor-informacion-venta__datos">
                    <Input 
                        type="textarea"
                        iconLeft="fa-user"
                        placeholder="Datos del cliente"
                        value={cliente?.nombre ?? 'Sin cliente'}
                        disabled={true}
                    />
                    <div className="contenedor-informacion-venta__datos__lateral">
                        <Input 
                            placeholder="Remisión"
                            value={`N° ${venta?.id}`}
                            disabled={true}
                        />
                        <Input 
                            type="date"
                            placeholder="Fecha"
                            value={venta?.fecha}
                            disabled={true}
                        />
                    </div>
                </div>

                <br />
                <TablaConceptos 
                    encabezados={[
                        { texto: 'Producto', tam: 'xl' },
                        { texto: 'Cant.',    tam: 'sm' },
                        { texto: 'Precio',   tam: 'sm' },
                        { texto: 'Total',    tam: 'sm' },
                    ]}
                    estructura={[
                        { col: 'nombre',   tipo: 'texto',  tam: 'xl', },
                        { col: 'cantidad', tipo: 'numero', tam: 'sm', incrementable: true },
                        { col: 'costo',    tipo: 'precio', tam: 'sm', },
                        { 
                            col: 'total',
                            tipo: 'precio', 
                            tam: 'sm',
                            text: (item) => numberFormat(item.cantidad * item.costo)
                        },
                    ]}
                    conceptos={conceptos}
                    modo='visual'
                    total={calcularTotalConceptos(conceptos)}
                />

                <br />
                <CardDetalle 
                    titulo='Pagaré'
                    texto='Debo y pagaré incondicionalmente a la orden de SERVI CARNES DE OCCIDENTE, S.A. DE C.V. en esta ciudad de. GUADALAJARA, JAL. El día 28 de Agosto de 2024 la cantidad de $25,000.00 (Veinticinco mil quinientos pesos) M.N. Valor de la mercancía recibidas a mi entera satisfacción. Este pagaré es mercantil y está regido por la Ley General del Títulos y Operaciones de crédito en su Artículo 173 parte final y artículos correctivos por no ser pagaré domiciliado.'
                    mostrarIcono={false}
                    modoInformacionGrande={true}
                />
            </div>
        )
    }
}

export default InformacionVenta