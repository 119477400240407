import { Tabla, buildData, ChipEstatus } from 'eureka-design'
import { useEffect, useState } from 'react'
import DetalleRemision from '../remisiones/detalle-remision'
import { useObtenerRemisionesClienteQuery } from '../../services/remision'
import { calcularKilos, calcularTotalConceptos, PAGADA, PENDIENTE_PAGO, SIN_PAGAR } from '../../js/remision'
import { numberFormat } from '../../js/util'
import FormAbonos from '../remisiones/form-abonos'

const ListaRemisionesUsuario = ({ UpTittle, usuario, tipo }) => {
    const { data:remisiones_cliente, isLoading } = useObtenerRemisionesClienteQuery({ clienteId: tipo === 'cliente' ? usuario?.id : null })
    const [remisiones_vendedor] = useState(usuario?.eureka_st_transacciones)

    const [remision, fijaRemision] = useState(false)
    const [mostrarDetalles, fijaMostrarDetalles] = useState(false)
    const [mostrarModalAbonos, cambiarEstadoModalAbonos] = useState(false)

    useEffect(() => {
        UpTittle(`Remisiones de ${usuario?.nombre ?? 'usuario'}`)
    })

    const handlerRegistrar = () => {
        
    }

    const handlerDetalles = (item) => {
        let remision = item.item
        fijaRemision(remision)
        fijaMostrarDetalles(true)
    }

    const handlerVerAbonos = (item) => {
        const remision = item.item
        fijaRemision(remision)
        cambiarEstadoModalAbonos(true)
    }

    if(isLoading) return
    else {
        return (
            <>
                <DetalleRemision 
                    remision={remision}
                    mostrar={mostrarDetalles}
                    onClose={() => { 
                        fijaMostrarDetalles(false) 
                    }}
                />

                <FormAbonos 
                    mostrar={mostrarModalAbonos}
                    cambiarEstado={cambiarEstadoModalAbonos}
                    remision={remision}
                    visual={true}
                />

                <Tabla
                    checkbox={false}
                    rowSize="medium"
                    noData="No hay remisiones aún"
                    busqueda={{ placeholder: "Buscar pedido...", keys: ["id"] }}
                    keys={[
                        {
                            icono: 'fa-copy',
                            text: "Todos",
                            detalle: tipo === 'cliente' ? remisiones_cliente.length : remisiones_vendedor.length,
                            filtro: () => true
                        },
                        {
                            icono: 'fa-circle-check',
                            text: "Pagadas",
                            detalle: tipo === 'cliente' ? remisiones_cliente.filter(d => d.cxp_cxc === PAGADA).length : remisiones_vendedor.filter(d => d.cxp_cxc === PAGADA).length,
                            filtro: item => item.item.cxp_cxc === PAGADA
                        },
                        {
                            icono: 'fa-file-invoice-dollar',
                            text: "Pendientes de pago",
                            detalle: tipo === 'cliente' ? remisiones_cliente.filter(d => d.cxp_cxc === PENDIENTE_PAGO).length : remisiones_vendedor.filter(d => d.cxp_cxc === PENDIENTE_PAGO).length,
                            filtro: item => item.item.cxp_cxc === PENDIENTE_PAGO
                        },
                        {
                            icono: 'fa-triangle-exclamation',
                            text: "Sin pagar",
                            detalle: tipo === 'cliente' ? remisiones_cliente.filter(d => d.cxp_cxc === SIN_PAGAR).length : remisiones_vendedor.filter(d => d.cxp_cxc === SIN_PAGAR).length,
                            filtro: item => item.item.cxp_cxc === SIN_PAGAR
                        },
                    ]}
                    headers={[
                        { key: 'id',        text: 'N° de Pedido',          type: 'text' },
                        { key: 'productos', text: 'Productos solicitados', type: 'text' },
                        { key: 'kilos',     text: 'Kg total',              type: 'number' },
                        { key: 'costo',     text: 'Costo',                 type: 'precio' },
                        { key: 'fecha',     text: 'Fecha de creación',     type: 'date' },
                        { key: 'estatus',   text: 'Estado',                type: 'text' },
                    ]}
                    data={buildData(tipo === 'cliente' ? remisiones_cliente : remisiones_vendedor, [
                        { key: 'id', type: 'text' },
                        { 
                            key: 'productos', 
                            type: 'text',
                            text: (item) => `${item.productos} productos`
                        },
                        { 
                            key: 'kilos',
                            type: 'number',
                            text: (item) => {
                                return calcularKilos(item?.eureka_st_transacciones_conceptos)
                            }
                        },
                        { 
                            key: 'costo',
                            type: 'precio',
                            text: (item) => {
                                return numberFormat(calcularTotalConceptos(item?.eureka_st_transacciones_conceptos))
                            }
                        },
                        { key: 'fecha', type: 'date' },
                        { 
                            key: 'estatus',
                            type: 'text',
                            text: (item) => {
                                let texto = '', tipo = ''
                                if(item.cxp_cxc === PAGADA) {
                                    texto = 'Pedido pagado'
                                    tipo = 'Aceptado'
                                }
                                else if(item.cxp_cxc === PENDIENTE_PAGO) {
                                    texto = 'Pendiente de pago'
                                    tipo = 'Pendiente'
                                }
                                else {
                                    texto = 'Sin pagar'
                                    tipo = 'Error'
                                }
                                return <ChipEstatus texto={texto} tipo={tipo}/>
                            }
                        },
                    ])}
                    botonesHead={[
                        { icono: 'fa-plus', tooltip: 'Agregar', onClick: handlerRegistrar },
                    ]}
                    botonesRow={[
                        { icono: 'fa-hand-holding-dollar', tooltip: 'Ver Abonos', onClick: handlerVerAbonos },
                    ]}
                    onClickRow={handlerDetalles}
                />
            </>
        )
    }
}

export default ListaRemisionesUsuario