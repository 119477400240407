import Columna, { TAM_SM } from "../Columna/Columna"
import Fila from "../Fila/Fila"

const SeccionAgregar = ({ 
    estructura = [], 
    icono = 'fa-plus',
    titulo = 'Agregar',
    indiceBoton = 0, 
    onClick = () => {} 
}) => {
    return (
        <Fila>
            {
                estructura.map((e, i) => {
                    let esBoton = indiceBoton === i
                    return ( 
                        <Columna 
                            key={i}
                            tam={e?.tam ?? TAM_SM}
                            estilos={esBoton ? {
                                color: 'var(--color-primario)',
                                fontSize: '16px'
                            } : {}}
                            seleccionable={esBoton}
                            icono={esBoton ? icono : null}
                            valor={esBoton ? titulo: null}
                            agregarBorde={i !== estructura.length - 1}
                            onClick={onClick}
                        />
                    )
                })
            }  
        </Fila>
    )
}

export default SeccionAgregar