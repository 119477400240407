import { useState } from 'react'
import CheckBox from '../CheckBox/CheckBox'
import './Categorias.css'
import { Card } from 'eureka-design'

const Categorias = ({
    titulo = 'Categorías',
    categorias = [],
    propValor = '',
    propHijo = '',
    onClick = () => {},
    onClickLimpiarFiltro = () => {}
}) => {
    const [indiceCategoria, fijaIndiceCategotia] = useState(null)
    const [indiceSubCategoria, fijaIndiceSubCategoria] = useState(null)

    return (
        <Card>
            <div className="seccion-categorias">
                <div className="seccion-categorias__seccion-titulo">
                    <h1 className="seccion-categorias__titulo">{titulo}</h1>
                    {
                        parseInt(indiceCategoria) >= 0 || parseInt(indiceSubCategoria >= 0) ? <i 
                            className="fa-solid fa-filter-circle-xmark seccion-categorias__icon-limpiar-filtro"
                            onClick={() => {
                                fijaIndiceCategotia(null)
                                fijaIndiceSubCategoria(null)
                                onClickLimpiarFiltro()
                            }}
                        ></i> : undefined
                    }
                </div>
                {
                    categorias.length === 0 ? 
                    <div className="seccion-categorias__sin-categorias">No hay categorías</div> :
                    categorias.map((c, i) => {
                        let seleccionado = i === indiceCategoria

                        return (
                            <div 
                                key={i} 
                                className="seccion-categorias__contenido" 
                            >
                                <CheckBox 
                                    seleccionado={i === indiceCategoria}
                                    texto={c[propValor]}
                                    onClick={() => {
                                        onClick(c)
                                        fijaIndiceCategotia(i)
                                        fijaIndiceSubCategoria(null)
                                    }}
                                    icono={`${ seleccionado ? 'fa-angle-up' : 'fa-angle-down' }`}
                                />
                                {
                                    c[propHijo].map((s, j) => {
                                        return (
                                            <div key={j} className={`seccion-categorias__contenido__sub-categorias ${seleccionado ? 'seccion-categorias__contenido__sub-categorias--visible' : ''}`}>
                                                <CheckBox
                                                    texto={s[propValor]}
                                                    textoPequeño={true}
                                                    seleccionado={j === indiceSubCategoria}
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        onClick(s)
                                                        fijaIndiceSubCategoria(j)
                                                    }}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>
        </Card>
    )
}

export default Categorias