import './Mapa.css'
import Busquedad from './Busquedad/Busquedad'
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api'
import { useEffect, useState } from 'react'
import { adaptarDatos } from '../../js/selector'

const prueba_direccion = 'Av. Miguel López de Legaspi 615, Colón Industrial, 44940 Guadalajara, Jal.'
const url_google_map = 'https://maps.googleapis.com/maps/api/geocode/json'
const librerias = ['places']

const Mapa = ({
    keyMap = '',
    estilosMap = {},
    zoom={},
    clientes = [],
    titulo = "Sin titulo",
    descripcion = "Sin descripción",
    detalles = [],
}) => {
    const { isLoaded } = useLoadScript({ googleMapsApiKey: keyMap, libraries: librerias })

    const [lat, fijaLat] = useState(0)
    const [lng, fijaLng] = useState(0)

    const geocodeAddress = async (address) => {    
        try {
            const response = await fetch(`${url_google_map}?address=${encodeURIComponent(address)}&key=${keyMap}`)
            const data = await response.json()

            if (data?.results[0]) {
                const { lat, lng } = data?.results[0]?.geometry?.location
                return { lat, lng }
            }
            else return null
        } 
        catch (error) {
            console.error("Error geocoding address:", error)
            return null;
        }
    }

    useEffect(() => {
        const inicializar = async () => {
            const coordenadas = await geocodeAddress(prueba_direccion)

            fijaLat(coordenadas.lat)
            fijaLng(coordenadas.lng)
        }

        inicializar()
    })

    const handlerCambiarCliente = (val, nom) => {
        console.log(val)
        console.log(nom)
    }

    if(!isLoaded) return

    return (
        <div className="contenedor-mapa">
            <Busquedad 
                placeholder="Buscar cliente..."
                icono="fa-magnifying-glass"
                nombre="cliente"
                valor={null}
                opciones={adaptarDatos(clientes, 'nombre', 'id')}
                onChange={handlerCambiarCliente}
                inputs={[
                    { placeholder: 'Municipio', nombre: "municipio", valor: null, opciones: [] },
                    { placeholder: 'Colonia', nombre: "colonia", valor: null, opciones: [] }
                ]}
            />

            <div className="contenedor-mapa__detalles">
                {
                    detalles.map((d, i) => {
                        return <p className="contenedor-mapa__detalles__subtitulo" key={i}>{d.texto}</p>
                    })
                }
            </div>
            <h3 className="contenedor-mapa__detalles__titulo">{titulo}</h3>
            <p className="contenedor-mapa__detalles__subtitulo">{descripcion}</p>

            <div className="contenedor-mapa__vista">
                <GoogleMap
                    mapContainerStyle={estilosMap}
                    zoom={zoom}
                    center={{ lat: lat, lng: lng }}
                >
                    <Marker position={{ lat: lat, lng: lng }} />
                </GoogleMap>
            </div>
        </div>
    )
}

export default Mapa