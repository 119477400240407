import { ModalForm, Select, Input } from 'eureka-design'
import Membrete from '../../components/Membrete/Membrete'
import logo from '../../imgs/icono.svg'
import ContenedorScroll from '../../components/ContenedorScroll/ContenedorScroll'
import TablaConceptos from '../../components/TablaConceptos/TablaConceptos'
import { numberFormat } from '../../js/util'
import { adaptarDatos, obtenerValor } from '../../js/selector'
import { useEffect, useMemo, useRef, useState } from 'react'
import Firma from '../../components/Firma/Firma'
import { useObtenerProductosQuery } from '../../services/producto'
import { useObtenerClientesQuery } from '../../services/cliente'
import { useObtenerCiudadesQuery } from '../../services/ciudad'
import { TABLA_DETALLES, useCrearConceptosMutation, useCrearDetallesMutation, useCrearRemisionMutation } from '../../services/remision'
import { useCrearMultimediaMutation } from '../../services/multimedia'
import { useCrearCreditoMutation, useObtenerCreditoMutation } from '../../services/credito'
import { mostrarAlerta, TIPO_ERROR } from '../../js/alerta'

export const NOM_FORM_REMISION = 'form_remision'
const TIPO_CIUDAD = 'ciudad'
const TIPO_FIRMA = 'firma'

const estiloDiv = {
    display: 'grid', 
    gridTemplate: '1fr / 1fr auto',
    gap: '5px',
}

const FormRemision = ({
    vendedor = null,
    remision = null,
    UpProps = () => {},
    mostrar = true,
    handlerMostrar = () => {},
    actualizarRemisiones = () => {},
    alerta = () => {},
}) => {
    const form_remision = useMemo(() => remision, [remision])

    const btnLimpiarFirma = useRef(null)

    const { data:productos, isLoading:cargandoProductos } = useObtenerProductosQuery({ estatus: 'activo' })
    const { data:clientes, isLoading:cargandoClientes } = useObtenerClientesQuery()
    const { data:ciudades, isLoading:caragandoCiudades } = useObtenerCiudadesQuery()

    const [crearRemision, { isLoading:actRemision, isError:remisionError, isSuccess:remisionCorrecta }] = useCrearRemisionMutation()
    const [crearConcepto, { isLoading:actConcepto, isError:conceptoError, isSuccess:conceptoCorrecto }] = useCrearConceptosMutation()
    const [crearDetalles, { isLoading:actDetalle, isError:detalleError, isSuccess:detalleCorrecto }] = useCrearDetallesMutation()
    const [crearMultimedia] = useCrearMultimediaMutation()
    const [crearCredito] = useCrearCreditoMutation()
    const [obtenerCreditoCliente] = useObtenerCreditoMutation()

    const [remisionId, fijaRemisionId] = useState(form_remision?.id)
    const [conceptos, fijaConceptos] = useState(form_remision?.eureka_st_transacciones_conceptos ?? [])
    const [detalles, fijaDetalles] = useState(form_remision?.eureka_st_transacciones_detalles ?? [])
    const [total, fijaTotal] = useState(0)

    useEffect(() => {
        if(mostrar) {
            fijaRemisionId(form_remision?.id)
            fijaConceptos(form_remision?.eureka_st_transacciones_conceptos)
            fijaDetalles(form_remision?.eureka_st_transacciones_detalles)
            fijaTotal(calcularTotal(conceptos))
        }
        // else no configurar componente
    }, [fijaTotal, conceptos, form_remision, mostrar])

    const calcularTotal = (conceptosAgregados = []) => {
        let total = 0
        conceptosAgregados?.forEach((concepto) => {
            total += Number(concepto.cantidad) * Number(concepto.costo)
        })
        return total
    }

    const handlerRemision = async (nom, val, body = {}) => {
        return await crearRemision({
            id: remisionId,
            body: {
                moneda: 'mxn',
                tipo: 'remision',
                vendedor: vendedor?.id,
                productos: conceptos?.length ?? 0,
                total: calcularTotal(conceptos),
                [nom]: val,
                ...body,
            }
        })
        .unwrap()
        .then((res) => {
            let remisionID = res?.mensaje?.id
            if(remisionID) {
                fijaRemisionId(remisionID)
                return remisionID
            }
            else return remisionId
        })
    }

    const handlerConcepto = async (conceptoId = null, body = {}) => {
        let remisionID = remisionId ? remisionId : await handlerRemision('productos', body.cantidad, { total: body.costo })

        return await crearConcepto({
            id: conceptoId,
            body: {
                ...body,
                transaccion: remisionID
            },
        })
        .unwrap()
        .then((res) => {
            let conceptoID = res?.mensaje?.id
            if(conceptoID) {
                const conceptosTemp = conceptos?.length >= 0 ? conceptos : []
                let conceptosEditados = [
                    ...conceptosTemp,
                    { id: conceptoID, ...body }
                ]
                return conceptosEditados
            }
            else return conceptos
        })
    }

    const handlerDetalle = async (nom, val, detalle = null, body = {}, tipo = null) => {
        let remisionID = remisionId ? remisionId : await handlerRemision('moneda', 'mxn')
        body = {
            transaccion: remisionID,
            [nom]: val,
            ...body,
        }

        return await crearDetalles({
            id: detalle?.id,
            body: body
        })
        .unwrap()
        .then((res) => {
            let detalleCreado = res?.mensaje
            if(detalleCreado?.id) {
                handlerActualizarDetalles(detalleCreado, tipo ? tipo : 'agregar')
                return detalleCreado
            }
            else {
                detalle = { ...detalle, ...body }
                handlerActualizarDetalles(detalle, tipo ? tipo : 'editar')
                return detalle
            }
        })
    }

    const handlerFirma = async (archivoURL) => {
        const archivo = await (await fetch(archivoURL)).blob()

        return await crearMultimedia({
            tabla: TABLA_DETALLES,
            media: {
                id: obtenerDetalle(detalles, TIPO_FIRMA)?.id,
                file: archivo,
                carpeta: 'firma',
                col: 'nombre'
            }
        })
        .unwrap()
        .then((res) => {
            let firma = res?.mensaje
            if(firma?.id) {
                handlerDetalle('tipo', TIPO_FIRMA, firma, {}, 'agregar')
                return firma
            }
            else {
                return obtenerDetalle(detalles, TIPO_FIRMA)
            }
        })
    }

    const handlerConceptoTabla = async (tipo, data, indice = 0) => {
        // console.log(`Indice seleccionado: ${indice}`)
        let conceptosEditados = []

        if(tipo === 'agregar') {
            const producto = data
            const res = await creditoDisponible(
                { id: form_remision?.cliente }, 
                [{ costo: producto?.costo, cantidad: 1 }]
            )

            if(res?.disponible) {
                actualizarCredito(parseFloat(producto?.costo), res?.credito, 'quitar')

                conceptosEditados = await handlerConcepto(null, {
                    nombre: producto?.nombre ?? 'Producto sin nombre',
                    cantidad: 1,
                    costo: producto?.costo,
                    producto: producto?.id,
                })

                handlerProps('eureka_st_transacciones_conceptos', conceptosEditados)
                fijaConceptos(conceptosEditados)
                handlerRemision('productos', conceptosEditados.length, { total: calcularTotal(conceptosEditados) })
            }
            else {
                mostrarAlerta(alerta, 'Crédito Insuficiente', 'El cliente no cuenta con el crédito suficiente para agreagar el producto', TIPO_ERROR, 3)
            }
        }
        else if(tipo === 'editar') {
            const concepto = data
            const seIncremento = concepto.cantidad > concepto.cantidad_ant
            const rangoIncremento = (seIncremento) ? (concepto.cantidad - concepto.cantidad_ant) : (concepto.cantidad_ant - concepto.cantidad)
            const res = await creditoDisponible(
                { id: form_remision?.cliente }, 
                [{ costo: concepto.costo, cantidad: rangoIncremento }]
            )
            const actualizarRegistro = seIncremento ? res?.disponible : true

            if(actualizarRegistro) {
                const total = concepto.costo * rangoIncremento
                if(seIncremento) actualizarCredito(total, res?.credito, 'quitar')
                else actualizarCredito(total, res?.credito, 'dar')

                conceptosEditados = [...conceptos]
                conceptosEditados[indice] = concepto

                handlerConcepto(concepto.id, { cantidad: concepto.cantidad })
                handlerProps('eureka_st_transacciones_conceptos', conceptosEditados)
                fijaConceptos(conceptosEditados)
                handlerRemision('productos', conceptosEditados.length, { total: calcularTotal(conceptosEditados) })
            }
            else {
                mostrarAlerta(alerta, 'Crédito Insuficiente', 'El cliente no cuenta con el crédito suficiente para agreagar el producto', TIPO_ERROR, 3)
            }
        }
        // else tipo no reconocido
    }

    const handlerActualizarDetalles = (detalleActualizado = null, tipo = 'agregar') => {
        const detallesTemp = detalles?.length >= 0 ? detalles : []
        let detallesEditados = [...detallesTemp]
        
        if(tipo === 'agregar') {
            detallesEditados.push(detalleActualizado)
        }
        else {
           detallesEditados = detallesEditados.map((detalle) => {
                if(detalle?.id === detalleActualizado?.id) return detalleActualizado
                else return detalle
           })
        }
        fijaConceptos(detallesEditados)
        handlerProps('eureka_st_transacciones_detalles', detallesEditados)
    }

    const handlerInput = (val, nom) => {
        if(nom === 'fecha') {
            let fecha = new Date(val)
            val = `${fecha.getFullYear()}-${fecha.getMonth()+1}-${fecha.getDate()}`
        }
        // else continuar normalmente

        handlerRemision(nom, val)
        handlerProps(nom, val)
    }

    const handlerSelect = async (val, nom) => {
        let valor = val.id
        if(nom === 'ciudad') {
            let ciudad = obtenerDetalle(detalles, TIPO_CIUDAD)
            handlerDetalle('nombre', valor, ciudad, { tipo: TIPO_CIUDAD })
        }
        else if(nom === 'cliente') {
            const res = await creditoDisponible(val.item, conceptos)
            if(res?.disponible) {
                if(res?.totalRemision > 0) {
                    const credito = await obtenerCreditoCliente({ id: form_remision?.cliente, col: 'usuario' }).unwrap().then(res => res)
                    actualizarCredito(res?.totalRemision, credito, 'dar')
                    actualizarCredito(res?.totalRemision, res?.credito, 'quitar')
                }
                // else no es necesario actualizar el crédito

                handlerRemision(nom, valor)
                handlerProps(nom, valor)
            }
            else {
                mostrarAlerta(alerta, 'Crédito Insuficiente', 'El cliente no cuenta con el crédito suficiente para esta remisión', TIPO_ERROR, 3)
            }
        }
        else {
            handlerRemision(nom, valor)
            handlerProps(nom, valor)
        }
    }

    const handlerProps = (nom, val) => {
        UpProps({
            [NOM_FORM_REMISION]: {
                ...form_remision,
                id: remisionId,
                [nom]: val,
            }
        })
    }

    const obtenerDetalle = (detalles = [], tipo = '') => {
        let res = detalles.filter((detalle) => detalle.tipo === tipo)
        return res.length > 0 ? res[0] : {}
    }

    const actualizarCredito = (total = 0, credito = null, tipo = 'dar') => {
        if(total > 0 && credito) {
            let creditoDisponible = parseFloat(credito?.puntos)
            creditoDisponible = creditoDisponible >= 0 ? creditoDisponible : 0

            crearCredito({ 
                id: credito?.id, 
                body: { 
                    puntos: tipo === 'dar' ? (creditoDisponible + total) : (creditoDisponible - total)  
                } 
            })
        }
        // else no es necesario actualizar el credito
    }

    const creditoDisponible = async (cliente = null, conceptosRemision = []) => {
        if(cliente?.id) {
            const totalRemision = calcularTotal(conceptosRemision)

            return await obtenerCreditoCliente({ id: cliente?.id, col: 'usuario' })
            .unwrap()
            .then((res) => {
                let creditoDisponible = parseFloat(res?.puntos)
                creditoDisponible = creditoDisponible > 0 ? creditoDisponible : 0

                // console.log(creditoDisponible)
                // console.log(totalRemision)

                return {
                    credito: res,
                    totalRemision: totalRemision,
                    disponible: creditoDisponible >= totalRemision,
                }
            })
        }
        else return true
    }

    if(cargandoProductos || cargandoClientes || caragandoCiudades) return
    else {
        return (
            <ModalForm
                show={mostrar}
                titulo="Crear una nueva remisión"
                // botones={[ { texto: "Crear remisión", onClick: () => {} } ]}
                onClose={() => { 
                    btnLimpiarFirma.current.click()
                    actualizarRemisiones()
                    handlerMostrar(false)
                }}
                isLoading={[actRemision, actConcepto, actDetalle]}
                isError={[remisionError, conceptoError, detalleError]}
                isSuccess={[remisionCorrecta, conceptoCorrecto, detalleCorrecto]}
            >
                <ContenedorScroll>
                    <Membrete 
                        logo={logo}
                        nombre="SERVI CARNES DE OCCIDENTE, S.A. DE C.V"
                        direccion="CALLE 7 N° 615 ZONA INDUSTRIAL"
                        telefono="(CONMUTADOR TEL. 31-45-07-23)"
                        ciudad="GUADALAJARA, JAL. C.P.44940"
                        rfc="R.F.C. SCO-960426-CFA"
                        extra="PRO. 9"
                    />
                    <div style={estiloDiv}>
                        <Select 
                            nombre="cliente"
                            options={adaptarDatos(clientes, 'nombre', 'id')}
                            value={obtenerValor(clientes, 'nombre', 'id', form_remision?.cliente, 'Seleccione un cliente...')}
                            changeFunction={handlerSelect}
                            busqueda={{ placeholder: 'Nombre del cliente' }}
                            isLoading={actRemision}
                        />
                        <Input 
                            nombre="tipo"
                            placeholder="Remisión"
                            value={remisionId ? `N° ${remisionId}` : ''}
                            disabled={true}
                        />
                    </div>
                    <div style={estiloDiv}>
                        <Select 
                            nombre="ciudad"
                            options={adaptarDatos(ciudades, 'ciudad', 'ciudad')}
                            value={obtenerValor(
                                ciudades, 
                                'ciudad', 
                                'ciudad', 
                                obtenerDetalle(detalles, TIPO_CIUDAD)?.nombre, 
                                'Seleccione una ciudad...'
                            )}
                            changeFunction={handlerSelect}
                            busqueda={{ placeholder: 'Ciudad' }}
                            isLoading={actRemision}
                        />
                        {
                            mostrar ? <Input 
                                nombre="fecha"
                                type="date"
                                value={form_remision?.fecha ?? undefined}
                                placeholder="Fecha"
                                changeFunction={handlerInput}
                                isLoading={actRemision}
                            /> : undefined
                        }
                    </div>

                    <TablaConceptos 
                        encabezados={[
                            { texto: 'ID Producto', tam: 'xl' },
                            { texto: 'Cant.',    tam: 'sm' },
                            { texto: 'Precio',   tam: 'sm' },
                            { texto: 'Total',    tam: 'sm' },
                        ]}
                        estructura={[
                            { 
                                col: 'nombre',
                                tipo: 'texto',
                                tam: 'xl', 
                                text: (item) => {
                                    return item?.nombre ?? 'Sin nombre'
                                }
                            },
                            { col: 'cantidad', tipo: 'numero', tam: 'sm', incrementable: true },
                            { col: 'costo',    tipo: 'precio', tam: 'sm', },
                            { 
                                col: 'total',
                                tipo: 'precio', 
                                tam: 'sm',
                                text: (item) => {
                                    return numberFormat(item.cantidad * item.costo)
                                },
                            },
                        ]}
                        conceptos={conceptos}
                        productos={adaptarDatos(productos, 'nombre', 'id')}
                        onChange={handlerConceptoTabla}
                        total={total}
                    />

                    <Firma 
                        titulo="Firma de conformidad"
                        subTitulo="Ingresa tu firma en el siguiente recuadro"
                        onSave={(img) => { handlerFirma(img) }}
                        refBtnBorrar={btnLimpiarFirma}
                    />
                </ContenedorScroll>
            </ModalForm>
        )
    }
}

export default FormRemision