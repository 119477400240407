import { Card } from 'eureka-design'
import Mapa from '../../components/Mapa/Mapa'
import { useObtenerClientesQuery } from '../../services/cliente'

const ZOOM = 17
const WIDTH = '100%'
const HEIGHT = '500px'
const RADIUS = '10px'

const MapaClientes = () => {
    const { data:clientes, isLoading } = useObtenerClientesQuery()

    if(isLoading) return
    else {
        return (
            <Card>
                <Mapa 
                    keyMap="AIzaSyCK17wiu82EPGZCTlp7c8Huvjnypyq4Z5w"
                    estilosMap={{ width: WIDTH, height: HEIGHT, borderRadius: RADIUS }}
                    zoom={ZOOM}
                    clientes={clientes}
                    titulo="Andrea López Martínez"
                    descripcion="Av. Miguel López de Legaspi 615, Colón Industrial, 44940 Guadalajara, Jal."
                    detalles={[
                        { texto: `${clientes.length} clientes en total` },
                        { texto: `${clientes.length} clientes en esta zona` },
                    ]}
                />
            </Card>
        )
    }
}

export default MapaClientes