import { DIA, MIN } from 'account-react-eurekasigma'

export const DOMINIO = window.location.origin
const URL_LOCAL = 'http://localhost:3000'
const URL_BASE_CRUFDEK = 'https://crud.eksuite.com/api'
const URL_BASE_FACTURA = 'https://invoice.eksuite.com/api'
const URL_BASE_TIMBRADO_PRUEBA = 'https://demo-facturacion.finkok.com/servicios/soap'
const URL_BASE_TIMBRADO = 'https://facturacion.finkok.com/servicios/soap'
const URL_BASE_PAGOS = 'https://payment.eksuite.com/api'

export const config = {
    contraseniaApp: 'servi_carnes_web_2024',
    colorPrimario: '#A20000',
    colorSecundario: '#004787',
    keyAccount: DOMINIO === URL_LOCAL ? 'Sc5YuIoIapS0xKGEOkhaNnTBdUuzrvaRFNhxvKdW' : 'nmjzcAr1QzlrE1E31ljRDG4Tik6FbyYefglIVrea',
    keyBD: 'S6UTg0zGqcm6FwyZwOW5FVDQGDtEGLOEHU5HU44r',
    keyEurekaPagos: '',
    urlCRUFDEK_V1: `${URL_BASE_CRUFDEK}/v1/CRUFDEK`,
    urlCRUFDEK: `${URL_BASE_CRUFDEK}/v2/CRUFDEK`,
    urlCRUFDEKArchivo: `${URL_BASE_CRUFDEK}/GetFile`,
    urlCrearFactura: `${URL_BASE_FACTURA}/v1/crear_factura`,
    urlArchivarFactura: `${URL_BASE_FACTURA}/v1/cancelar_factura`,
    urlTimbrarFactura: `${DOMINIO === URL_LOCAL ? URL_BASE_TIMBRADO_PRUEBA : URL_BASE_TIMBRADO}/stamp.wsdl`,
    urlCancelarFactura: `${DOMINIO === URL_LOCAL ? URL_BASE_TIMBRADO_PRUEBA : URL_BASE_TIMBRADO}/cancel.wsdl`,
    urlEurekaPagos: `${URL_BASE_PAGOS}/v2/stripe`,
    expCookie: { cantidad: 15, tipo: DIA },
    inactividad: { cantidad: 15, tipo: DIA },
    tiempoRefrescar: { cantidad: 50, tipo: MIN },
    nomCookieUsuario: 'usuario',
    nomCookieToken: 'tokenSesion',
}