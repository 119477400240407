const URL_WHATSAPP_WEB = 'https://wa.me'

const CODIGO_MEXICO = '52'

const limpiar = (numero = '') => {
    return numero.replaceAll(/[\s\-+()]/g, '')
}

const verificarCodigoPais = (numero = '', codigo = CODIGO_MEXICO) => {
    return (numero.indexOf(codigo) === 0) ? numero : `${codigo}${numero}`
}

export const enviarMensaje = (numero = '', mensaje = '') => {
    if(numero?.length > 0) {
        numero = verificarCodigoPais(limpiar(numero))
        
        const url = `${URL_WHATSAPP_WEB}/${numero}?text=${encodeURIComponent(mensaje)}`
        window.open(url, '_blank')
        return true
    }
    else return false
}