import Columna, { TAM_SM } from "../Columna/Columna"
import Fila from "../Fila/Fila"
import { Select } from "eureka-design"

const FilaAgregar = ({
    estructura = [],
    totalConceptos = 0,
    prodcutos = [],
    onChange = () => {},
}) => {
    return (
        <Fila agregarFondo={((totalConceptos + 1) % 2) === 0}>
            {
                estructura.map((e, i) => {
                    return (
                        <Columna 
                            key={i}
                            tam={e?.tam ?? TAM_SM}
                            agregarBorde={i !== estructura.length - 1}
                        >
                            { 
                                i === 0 ? 
                                    <div style={{ width: '100%' }}>
                                        <Select 
                                            nombre="producto_seleccionado"
                                            value={'Buscar un producto...'}
                                            options={prodcutos}
                                            busqueda={{
                                                placeholder: 'ID de producto'
                                            }}
                                            changeFunction={onChange}
                                        /> 
                                    </div> : undefined 
                            }
                        </Columna>
                    )
                })
            }
        </Fila>
    )
}

export default FilaAgregar