import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../config/config'
import { EkQuery } from './query'
import { token } from 'account-react-eurekasigma'

const TABLA_PRODUCTO = 'EurekaStProducto'
const TABLA_PRODUCTO_PRECIO = 'EurekaStProductosPrecio'
export const TABLA_PRODUCTO_FOTO = 'EurekaStProductosFoto'

export const productoAPI = createApi({
    reducerPath: 'productoAPI',
    baseQuery: fetchBaseQuery({ baseUrl: config.urlCRUFDEK }),
    tagTypes: ['Productos'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerProductos: builder.query({
            /**
             * @param {{ estatus: string, id: number, categoria: number }} data 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_PRODUCTO)
                body = (data?.estatus) ? body.where('estatus', data.estatus) : body
                body = (data?.id) ? body.where('id', data.id) : body
                body = (data?.categoria) ? body.where('nivel', data.categoria) : body

                return {
                    url: `/READ?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body.with('eureka_st_productos_precios')
                              .with('eureka_st_productos_fotos.eureka_cr_multimedia')
                              .with('eureka_eui_select')
                              .get()
                }
            },
        }),
        crearProducto: builder.mutation({
            /**
             * @param {{ id: number, body: object }} data 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_PRODUCTO)
                body = (data?.id) ? body.where('id', data.id).update(data.body) : body.create(data.body)

                return {
                    url: `/CUFD?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            },
        }),
        crearPrecioProducto: builder.mutation({
            /**
             * @param {{ id: number, body: object }} data 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_PRODUCTO_PRECIO)
                body = (data?.id) ? body.where('id', data.id).update(data.body) : body.create(data.body)

                return {
                    url: `/CUFD?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            },
        }),
    })
})


export const { 
    useObtenerProductosQuery,
    useCrearProductoMutation,
    useCrearPrecioProductoMutation,
} = productoAPI