import { ModalForm, Input } from 'eureka-design'
import { useEffect, useState } from 'react'
import { useCrearCreditoMutation } from '../../services/credito'

const FormCredito = ({ 
    mostrar = false, 
    cambiarEstado = () => {}, 
    actualizar = () => {},
    cliente = null,

}) => {
    const [crearCredito, { isLoading:actCredito, isError:creditoError, isSuccess:creditoBien }] = useCrearCreditoMutation()

    const [clienteId, fjiaClienteId] = useState(cliente?.id)
    const [credito, fijaCredito] = useState(null)

    const [titulo, fijaTitulo] = useState(`Otorgar crédito a ${cliente?.nombre}`)

    useEffect(() => {
        const obtenerRegistroCredito = () => {
            if(cliente?.eureka_pa_billeteras?.length > 0) {
                return cliente.eureka_pa_billeteras[0]
            }
            else return null
        }

        const creditoAct = obtenerRegistroCredito(cliente)
        fijaTitulo(`Otorgar crédito a ${cliente?.nombre}`)
        fjiaClienteId(cliente?.id)
        fijaCredito(creditoAct)
    }, [cliente])

    const handlerCredito = async (nom, val) => {
        return await crearCredito({
            id: credito?.id,
            body: {
                [nom]: val,
                usuario: clienteId,
            }
        })
        .unwrap()
        .then((res) => {
            let id = res?.mensaje?.id
            if(id) {
                fijaCredito(res.mensaje)
                return res.mensaje
            }
            else {
                return credito
            }
        })
    }

    const handlerInput = (val, nom) => {
        val = parseFloat(val) >= 0 ? val : '0'
        handlerCredito(nom, val)
    }

    return (
        <ModalForm
            show={mostrar}
            titulo={titulo}
            onClose={() => {
                actualizar()
                cambiarEstado(false)
            }}
            isLoading={[actCredito]}
            isError={[creditoError]}
            isSuccess={[creditoBien]}
        >
            <Input 
                type="number"
                placeholder="Crédito"
                iconLeft="fa-credit-card"
                nombre="puntos"
                value={credito?.puntos ?? ''}
                changeFunction={handlerInput}
                isLoading={actCredito}
                min={0}
                max={999999}
            />
        </ModalForm>
    )
}

export default FormCredito