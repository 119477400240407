import { Tabla, buildData, ChipEstatus } from 'eureka-design'
import FormRemision, { NOM_FORM_REMISION } from './form-remision'
import { useEffect, useState } from 'react'
import { TIPO_REMISION, useCrearRemisionMutation, useObtenerRemisionesQuery } from '../../services/remision'
import DetalleRemision from './detalle-remision'
import { enviarMensaje } from '../../js/whatsapp'
import { MENSAJE_WHATSAPP, NOM_CLIENTE, NOM_ENLACE } from '../../js/remision'
import CryptoJS from 'crypto-js'
import { config } from '../../config/config'
import { mostrarAlerta, TIPO_ERROR } from '../../js/alerta'
import FormAbonos from './form-abonos'

const ListaRemisiones = ({ ShowAlert, usuario, UpProps, form_remision }) => {
    const { data, isLoading, refetch } = useObtenerRemisionesQuery({ tipo: TIPO_REMISION })

    const [mostrarModalRegistro, cambiarEstadoModalRegistro] = useState(false)
    const [mostrarModalDetalle, cambiarEstadoModalDetalle] = useState(false)
    const [mostrarModalAbonos, cambiarEstadoModalAbonos] = useState(false)
    const [remision, fijaRemision] = useState(form_remision)

    const [crearRemision] = useCrearRemisionMutation()

    useEffect(() => {
        fijaRemision(form_remision)
    }, [form_remision])

    const handlerRegistrar = () => {
        UpProps({ [NOM_FORM_REMISION]: null })
        fijaRemision(null)
        cambiarEstadoModalRegistro(true)
    }

    const handlerEditar = (item) => {
        const remision = item.item
        let abonado = parseFloat(remision?.abonado)
        abonado = abonado > 0 ? abonado : 0

        if(remision.cxp_cxc !== 'liquidado') {
            if(abonado === 0) {
                fijaRemision(remision)
                cambiarEstadoModalRegistro(true)
            }
            else {
                mostrarAlerta(ShowAlert, 'Operación no Permitida', 'La remisión ya comenzo a ser abonada', TIPO_ERROR, 3)   
            }
        }
        else {
            mostrarAlerta(ShowAlert, 'Operación no Permitida', 'La remisión ya a sido pagada', TIPO_ERROR, 3) 
        }
    }

    const handlerDetalles = (item) => {
        let remision = item.item
        // console.log(remision)
        
        fijaRemision(remision)
        cambiarEstadoModalDetalle(true)
    }

    const handlerEnviarInformacion = (item) => {
        let remision = item.item
        let guia = remision?.eureka_en_guia
        let cliente = remision?.eureka_bs_cliente
        let mensaje = MENSAJE_WHATSAPP
        let idEncriptado = CryptoJS.AES.encrypt(String(remision.id), config.contraseniaApp).toString()

        if(guia?.estatus === 'enviado')
            mostrarAlerta(ShowAlert, 'Error al enviar', 'El mensaje ya se envío anteriormente', 'error')
        else {
            mensaje = mensaje.replace(NOM_CLIENTE, cliente?.nombre ?? 'cliente')
            mensaje = mensaje.replace(NOM_ENLACE, `${window.location.origin}/informacion_pedido?id=${idEncriptado}`)
            if(enviarMensaje(cliente?.telefono, mensaje))
                mostrarAlerta(ShowAlert, 'Mensaje enviado', 'El mensaje se ha envío correctamente')
            else
                mostrarAlerta(ShowAlert, 'Teléfono Erroneo', 'El teléfono es incorrecto o no existe', 'error')
        }
    }

    const handlerAbonar = (item) => {
        const remision = item.item
        if(remision.cxp_cxc !== 'liquidado') {
            // console.log(remision)
            fijaRemision(remision)
            cambiarEstadoModalAbonos(true)
        }
        else {
          mostrarAlerta(ShowAlert, 'No puedes abonar', 'La remisión ya a sido pagada', TIPO_ERROR, 3)  
        }
    }

    const handlerLiquidar = (item) => {
        const remision = item.item

        if(remision.cxp_cxc !== 'liquidado') {
            crearRemision({ id: remision.id, body: { cxp_cxc: 'liquidado' } }).unwrap().then(() => { refetch() })
        }
        else {
            mostrarAlerta(ShowAlert, 'Operación no Permitida', 'La remisión ya a sido pagada', TIPO_ERROR, 3)  
        }
    }

    if(isLoading) return
    else {
        return (
            <>
                <FormRemision 
                    vendedor={usuario}
                    remision={remision}
                    UpProps={UpProps}
                    mostrar={mostrarModalRegistro}
                    handlerMostrar={cambiarEstadoModalRegistro}
                    actualizarRemisiones={refetch}
                    alerta={ShowAlert}
                />

                <DetalleRemision 
                    remision={remision}
                    mostrar={mostrarModalDetalle}
                    onClose={() => {
                        cambiarEstadoModalDetalle(false) 
                        fijaRemision(null)
                    }}
                />

                <FormAbonos 
                    mostrar={mostrarModalAbonos}
                    cambiarEstado={cambiarEstadoModalAbonos}
                    actualizarRegistros={refetch}
                    alerta={ShowAlert}
                    remision={remision}
                />

                <Tabla
                    checkbox={false}
                    rowSize="medium"
                    noData="No hay remisiones aún"
                    busqueda={{ placeholder: "Buscar...", keys: ["id"] }}
                    keys={[
                        {
                            icono: 'fa-copy',
                            text: "Todos",
                            detalle: data.length,
                            filtro: () => true
                        },
                        {
                            icono: 'fa-circle-check',
                            text: "Pagadas",
                            detalle: data.filter((d) => d.cxp_cxc === 'liquidado').length,
                            filtro: item => item.item.cxp_cxc === "liquidado"
                        },
                        {
                            icono: 'fa-file-invoice-dollar',
                            text: "Pendientes de pago",
                            detalle: data.filter((d) => d.cxp_cxc === 'cobrar').length,
                            filtro: item => item.item.cxp_cxc === "cobrar"
                        },
                        {
                            icono: 'fa-triangle-exclamation',
                            text: "Sin pagar",
                            detalle: data.filter((d) => d.cxp_cxc === 'pagar').length,
                            filtro: item => item.item.cxp_cxc === "pagar"
                        },
                    ]}
                    headers={[
                        { key: 'id',               text: 'N° de Pedido',          type: 'text' },
                        { key: 'cliente',          text: 'Cliente',               type: 'text' },
                        { key: 'estatus_whatsapp', text: 'Enviado por Whatsapp',  type: 'text' },
                        { key: 'productos',        text: 'Productos Solicitados', type: 'text' },
                        { key: 'total',            text: 'Kg Total',              type: 'number' },
                        { key: 'costo',            text: 'Costo',                 type: 'precio' },
                        { key: 'fecha',            text: 'Fecha de Creación',     type: 'date' },
                        { key: 'cxp_cxc',          text: 'Estado',                type: 'text' },
                    ]}
                    data={buildData(data, [
                        { key: 'id', type: 'text', text: (item) => `N° ${item.id}` },
                        { 
                            key: 'cliente', 
                            type: 'text', 
                            text: (item) => {
                                return item?.eureka_bs_cliente?.nombre ?? 'Sin cliente'
                            }
                        },
                        { 
                            key: 'estatus_whatsapp', 
                            type: 'text', 
                            text: (item) => {
                                let guia = item?.eureka_en_guia
                                let texto = ''
                                let tipo = ''

                                if(guia?.estatus === 'enviado') {
                                    texto = 'Enviada'
                                    tipo = 'Aceptado'
                                }
                                else if(guia?.estatus === 'esperando') {
                                    texto = 'Sin enviar'
                                    tipo = 'Error'
                                }
                                else {
                                    texto = 'Sin enviar'
                                    tipo = 'Error'
                                }
                                return <ChipEstatus texto={texto} tipo={tipo}/>
                            }
                        },
                        { 
                            key: 'productos', 
                            type: 'text',
                            text: (item) => {
                                let conceptos = item?.eureka_st_transacciones_conceptos
                                return `${conceptos.length} productos`
                            }
                        },
                        { 
                            key: 'kilo', 
                            type: 'number',
                            text: (item) => {
                                let conceptos = item?.eureka_st_transacciones_conceptos
                                let total = 0

                                conceptos.forEach((c) => {
                                    total += parseFloat(c.cantidad)
                                })
                                return total
                            }
                        },
                        { key: 'total', type: 'precio' },
                        { key: 'fecha', type: 'date' },
                        { 
                            key: 'cxp_cxc', 
                            type: 'text',
                            text: (item) => {
                                let texto = '', tipo = ''
                                if(item.cxp_cxc === 'liquidado') {
                                    texto = 'Pedido pagado'
                                    tipo = 'Aceptado'
                                }
                                else if(item.cxp_cxc === 'cobrar') {
                                    texto = 'Pendiente de pago'
                                    tipo = 'Pendiente'
                                }
                                else {
                                    texto = 'Sin pagar'
                                    tipo = 'Error'
                                }
                                return <ChipEstatus texto={texto} tipo={tipo}/>
                            }
                        },
                    ])}
                    botonesHead={[
                        { icono: 'fa-plus', tooltip: 'Agregar', onClick: handlerRegistrar },
                    ]}
                    botonesRow={[
                        { icono: 'fa-hand-holding-dollar', tooltip: 'Abonar', onClick: handlerAbonar },
                        { icono: 'fa-cash-register', tooltip: 'Liquidar', onClick: handlerLiquidar },
                        { icono: 'fa-pen', tooltip: 'Editar', onClick: handlerEditar },
                        { icono: 'fa-brands fa-whatsapp', tooltip: 'Enviar Información', onClick: handlerEnviarInformacion },
                    ]}
                    onClickRow={handlerDetalles}
                />
            </>
        )
    }
}

export default ListaRemisiones