import { ModalForm, Tabla, buildData, Input } from 'eureka-design'
import { useEffect, useMemo, useState } from 'react'
import { useCrearAbonoMutation, useCrearCreditoMutation, useObtenerCreditoMutation } from '../../services/credito'
import { adaptarFechaBD, numberFormat } from '../../js/util'
import { mostrarAlerta, TIPO_ERROR } from '../../js/alerta'
import { useCrearRemisionMutation } from '../../services/remision'
import CardDetalle from '../../components/CardDetalle/CardDetalle'
import { obtenerCredito } from '../../js/credito'
import { enviarMensaje } from '../../js/whatsapp'

const displayFlex = {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 0',
    gap: '30px',
}

export const TIPO_ABONO = 'abono' 

export const obtenerAbonos = (detalles = []) => {
    return detalles.filter((detalle) => {
        return detalle.tipo === TIPO_ABONO
    })
}

export const calcularTotalAbonos = (abonos = []) => {
    let total = 0
    abonos.forEach((abono) => {
        total += parseFloat(abono.texto)
    })
    return total
}

export const calcularFaltante = (remision = {}, abonos = []) => {
    const totalAbono = calcularTotalAbonos(abonos)
    return parseFloat(remision?.total) - totalAbono
}

const FormAbonos = ({
    mostrar = false,
    cambiarEstado = () => {},
    actualizarRegistros = () => {},
    alerta = () => {},
    remision = null,
    visual = false,
}) => {
    const form_remision = useMemo(() => remision, [remision])

    const [crearAbono, { isLoading:actAbono, isError:abonoError, isSuccess:abonoBien }] = useCrearAbonoMutation()
    const [crearRemision] = useCrearRemisionMutation()
    const [crearCredito] = useCrearCreditoMutation()
    const [obtenerCreditoCliente] = useObtenerCreditoMutation()

    /** CONFIGURACIÓN */
    const [titulo, fijaTitulo] = useState('')
    const [remisionId, fijaRemisionId] = useState(form_remision?.id)

    /** FORM */
    const [agregarAbono, cambiarEstadoAgregarAbono] = useState(false)
    const [totalAbono, fijaTotalAbono] = useState(null)
    const [abonos, fijaAbonos] = useState([])

    /** VISUAL */
    const [totalAbonado, fijaTotalAbonado] = useState(0)
    const [faltante, fijaFaltante] = useState(0)

    useEffect(() => {
        if(mostrar) {
            console.log(form_remision)
            const abonosAct = obtenerAbonos(form_remision?.eureka_st_transacciones_detalles)

            fijaTitulo(`Detalles de abonos para pedido N°${form_remision?.id}`)
            fijaRemisionId(form_remision?.id)

            fijaAbonos(abonosAct)
            
            fijaTotalAbonado(numberFormat(calcularTotalAbonos(abonosAct)))
            fijaFaltante(numberFormat(calcularFaltante(form_remision, abonosAct)))
            
        }
        // else no inicializar los datos
    }, [form_remision, mostrar])

    const hanlderRemision = (nom, abonos = []) => {
        const faltante = calcularFaltante(form_remision, abonos)

        crearRemision({
            id: remisionId,
            body: { 
                [nom]: calcularTotalAbonos(abonos),
                cxp_cxc: parseFloat(faltante) === parseFloat(0) ? 'liquidado' : 'cobrar'
            }
        })
        .unwrap()
        .then(() => {
            fijaTotalAbonado(numberFormat(calcularTotalAbonos(abonos)))
            fijaFaltante(numberFormat(faltante))
        })
    }

    const handlerCredito = (abonado = 0) => {
        obtenerCreditoCliente({ id: obtenerCredito(form_remision?.eureka_bs_cliente)?.id })
        .unwrap()
        .then((res) => {
            const credito = res
            if(credito) {
                crearCredito({
                    id: credito.id,
                    body: {
                        puntos: parseFloat(credito.puntos) + parseFloat(abonado)
                    }
                })
            }
            // else no cuenta con un crédito ?
        })
    }

    const handlerAbono = async () => {
        if(parseFloat(totalAbono) > 0) {
            const faltante = calcularFaltante(form_remision, abonos)

            if(totalAbono <= faltante) {
                const fecha = new Date()
                return await crearAbono({
                    body: {
                        transaccion: remisionId,
                        tipo: TIPO_ABONO,
                        nombre: adaptarFechaBD(fecha),
                        texto: totalAbono,
                    }
                })
                .unwrap()
                .then((res) => {
                    const id = res?.mensaje?.id

                    cambiarEstadoAgregarAbono(false)
                    if(id) {
                        const abonosActualizados = [...abonos, res.mensaje]
                        fijaAbonos(abonosActualizados)
                        hanlderRemision('abonado', abonosActualizados)
                        handlerCredito(totalAbono)
                        enviarMensaje(
                            form_remision?.eureka_bs_cliente?.telefono, 
                            `Tu abono por ${numberFormat(parseFloat(totalAbono))} ha sido confirmado.`
                        )
                        return true
                    }
                    else return false
                })
            }
            else {
                mostrarAlerta(alerta, 'Abono no válido', 'la cantidad debe no debe superar el faltante', TIPO_ERROR, 3)
            }
        }
        else {
            mostrarAlerta(alerta, 'Abono no válido', 'la cantidad debe ser mayor a $0 para poder guardarlo', TIPO_ERROR, 3)
        }
    }

    const handlerInput = (val, nom) => {
        val = parseFloat(val) > 0 ? val : null
        fijaTotalAbono(val)
    }

    const hanlderAbonar = () => {
        if(!agregarAbono) {
            const faltante = calcularFaltante(form_remision, abonos)

            if(faltante > 0) {
                fijaTotalAbono(null)
                cambiarEstadoAgregarAbono(true)
            }
            else {
                mostrarAlerta(alerta, 'Abono no válido', 'La venta ya a sido liquidada', TIPO_ERROR, 3)
            }
        }
        // else continuar normalmente
    }

    const handlerLimpiar = () => {
        fijaRemisionId(null)
        fijaTitulo('')
        fijaTotalAbono(null)
        fijaAbonos([])
        cambiarEstadoAgregarAbono(false)
    }
    
    return (
        <ModalForm
            titulo={titulo}
            show={mostrar}
            isLoading={[actAbono]}
            isError={[abonoError]}
            isSuccess={[abonoBien]}
            onClose={() => {
                handlerLimpiar()
                actualizarRegistros()
                cambiarEstado(false)
            }}
            botones={visual ? [] : [
                {
                    texto: 'Guardar abono',
                    onClick: handlerAbono,
                    iconLeft: 'fa-piggy-bank',
                }
            ]}
        >
            <div style={displayFlex}>
                <CardDetalle 
                    titulo="Total abonado"
                    texto={totalAbonado}
                    icono="fa-hand-holding-dollar"
                    color="#00796B"
                    tam="sm"
                />
                <CardDetalle 
                    titulo="Faltante"
                    texto={faltante}
                    icono="fa-cash-register"
                    color="#D32F2F"
                    tam="sm"
                />
            </div>
            {
                mostrar ? 
                    <Tabla 
                        checkbox={false}
                        rowSize="medium"
                        noData="Aún no hay abonos"
                        headers={[
                            { key: 'texto',  text: 'Total abonado',  type: 'precio' },
                            { key: 'tipo',   text: 'Tipo',           type: 'text' },
                            { key: 'nombre', text: 'Fecha de abono', type: 'date' },
                        ]}
                        data={buildData(abonos, [
                            { key: 'texto',  type: 'precio', text: (item) => numberFormat(item?.texto) },
                            { key: 'tipo',   type: 'text', text: () => 'Efectivo' },
                            { key: 'nombre', type: 'date', },   
                        ])}
                        botonesHead={visual ? [] : [
                            { icono: 'fa-plus', tooltip: 'Agregar', onClick: hanlderAbonar },
                        ]}
                    /> : undefined
            }

            {
                agregarAbono ? 
                    <div>
                        <br />
                        <h3>Agregar un nuevo abono</h3>
                        <Input 
                            type="number"
                            placeholder="Total"
                            iconLeft="fa-money-bill"
                            nombre="texto"
                            changeFunction={handlerInput}
                            isLoading={actAbono}
                            min={1}
                            max={999999}
                        />
                    </div> : undefined
            }
        </ModalForm>
    )
}

export default FormAbonos