import { ModalForm, ImageInput, Input } from 'eureka-design'
import ContenedorScroll from '../../components/ContenedorScroll/ContenedorScroll'
import { useCrearClienteMutation, useCrearDomicilioMutation } from '../../services/cliente'
import { useEffect, useMemo, useState } from 'react'
import { useCrearMultimediaMutation } from '../../services/multimedia'
import { useDarRolMutation } from '../../services/rol'
import { ROL_CLIENTE } from '../../js/rol'

export const NOM_FORM_CLIENTE = 'form_cliente'

const FormCliente = ({
    cliente = null,
    mostrar = true,
    handlerMostrar = () => {},
    actualizarClientes = () => {},
    UpProps = () => {},
    titulo = 'Agregar un nuevo cliente',
}) => {
    const form_cliente = useMemo(() => cliente, [cliente])

    const [crearMultimedia, {isLoading:actualizandoFoto, isError:fotoErronea, isSuccess:fotoActualizada}] = useCrearMultimediaMutation()
    const [crearCliente, {isLoading:actualizandoCliente, isError:clienteErroneo, isSuccess:clienteActualizado}] = useCrearClienteMutation()
    const [crearDomicilio, {isLoading:actualizandoDomicilio, isError:domicilioErronea, isSuccess:domicilioActualizado}] = useCrearDomicilioMutation()
    const [darRol] = useDarRolMutation()

    const [clienteId, fijaClienteId] = useState(form_cliente?.id)
    const [domicilioId, fijaDomicilioId] = useState(form_cliente?.domicilio?.id)

    useEffect(() => {
        fijaClienteId(form_cliente?.id)
        fijaDomicilioId(form_cliente?.domicilio?.id)
    }, [form_cliente])

    const handlerFoto = async (e) => {
        let id = clienteId ? clienteId : await handlerCliente('nombre', '')
        let archivo = e.target.files[0]

        handlerProps('foto', archivo)
        return await crearMultimedia({
            tabla: 'EurekaAcUsuario',
            media: {
                id: id,
                file: archivo,
                carpeta: 'cliente',
                col: 'foto',
            }
        })
        .unwrap()
        .then((res) => {
            if(res?.mensaje?.id) {
                let id = res.mensaje.id
                fijaClienteId(id)
                return id
            }
            else {
                return clienteId
            }
        })
    }

    const handlerCliente = async (nom, val) => {
        return await crearCliente({
            id: clienteId,
            body: {
                [nom]: val
            }
        })
        .unwrap()
        .then((res) => {
            if(res?.mensaje?.id) {
                let id = res.mensaje.id
                fijaClienteId(id)
                darRol({
                    body: {
                        usuario: id,
                        rol: ROL_CLIENTE.id
                    }
                })
                return id
            }
            else {
                return clienteId
            }
        })
    }

    const handlerDomicilio = async (nom, val) => {
        let id = clienteId ? clienteId : await handlerCliente('nombre', '')

        return await crearDomicilio({
            id: domicilioId,
            body: {
                [nom]: val,
                usuario: id,
                tipo: 'principal',
                estatus: 'activo',
            }
        })
        .unwrap()
        .then((res) => {
            if(res?.mensaje?.id) {
                let id = res.mensaje.id
                fijaDomicilioId(id)
                return id
            }
            else {
                return domicilioId
            }
        })
    }

    const handlerInputCliente = (val, nom) => {
        handlerCliente(nom, val)
        handlerProps(nom, val)
    }

    const handlerInputDomicilio = (val, nom) => {
        handlerDomicilio(nom, val)
        handlerProps(nom, val, 'domicilio')
    }

    const handlerProps = (nom, val, nomData = '') => {
        let data = (nomData?.length > 0) ? 
            { 
                [nomData]: {
                    ...cliente[nomData], 
                    [nom]: val 
                } 
            } : 
            { [nom]: val }

        UpProps({
            [NOM_FORM_CLIENTE]: {
                ...cliente,
                id: cliente?.id ?? clienteId,
                domicilio_id: cliente?.domicilio_id ?? domicilioId,
                ...data
            }
        })
    }

    return (
        <ModalForm 
            show={mostrar}
            onClose={() => {
                handlerMostrar(false)
                actualizarClientes()
            }}
            titulo={titulo}
            isLoading={[actualizandoCliente, actualizandoFoto, actualizandoDomicilio]}
            isError={[clienteErroneo, fotoErronea, domicilioErronea]}
            isSuccess={[clienteActualizado, fotoActualizada, domicilioActualizado]}
        >
            <ContenedorScroll>
                {
                    mostrar ? <ImageInput 
                        width={250}
                        height={250}
                        preview={form_cliente?.foto}
                        changeFunction={handlerFoto}
                    /> : undefined
                }
                <Input 
                    iconLeft="fa-user"
                    placeholder="Nombre"
                    nombre="nombre"
                    changeFunction={handlerInputCliente}
                    value={form_cliente?.nombre ?? ''}
                    isLoading={actualizandoCliente}
                />
                <Input 
                    iconLeft="fa-envelope"
                    placeholder="Correo eléctronico"
                    nombre="correo"
                    changeFunction={handlerInputCliente}
                    value={form_cliente?.correo ?? ''}
                    isLoading={actualizandoCliente}
                />
                <Input 
                    iconLeft="fa-phone"
                    placeholder="Teléfono"
                    nombre="telefono"
                    changeFunction={handlerInputCliente}
                    value={form_cliente?.telefono ?? ''}
                    isLoading={actualizandoCliente}
                />
                <h4>Dirección</h4>
                <div style={{ display: 'grid', gap: '8px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <Input 
                            placeholder="Calle"
                            nombre="calle"
                            changeFunction={handlerInputDomicilio}
                            value={form_cliente?.domicilio?.calle ?? ''}
                            isLoading={actualizandoDomicilio}
                        />
                        <div>
                            <Input
                                placeholder="N° exterior"
                                nombre="numero"
                                changeFunction={handlerInputDomicilio}
                                value={form_cliente?.domicilio?.numero ?? ''}
                                isLoading={actualizandoDomicilio}
                            />
                        </div>
                        <div>
                            <Input
                                placeholder="N° interior"
                                nombre="interior"
                                changeFunction={handlerInputDomicilio}
                                value={form_cliente?.domicilio?.interior ?? ''}
                                isLoading={actualizandoDomicilio}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <div style={{ width: '57%' }}>
                            <Input 
                                placeholder="Código postal"
                                nombre="referencias"
                                changeFunction={handlerInputDomicilio}
                                value={form_cliente?.domicilio?.referencias ?? ''}
                                isLoading={actualizandoDomicilio}
                            />
                        </div>
                        <Input
                            placeholder="Municipio"
                            nombre="nombre"
                            changeFunction={handlerInputDomicilio}
                            value={form_cliente?.domicilio?.nombre ?? ''}
                            isLoading={actualizandoDomicilio}
                        />
                        <Input
                            placeholder="Colonia"
                            nombre="entrecalles"
                            changeFunction={handlerInputDomicilio}
                            value={form_cliente?.domicilio?.entrecalles ?? ''}
                            isLoading={actualizandoDomicilio}
                        />
                    </div>
                </div>
            </ContenedorScroll>
        </ModalForm>
    )
}

export default FormCliente