import { useState } from 'react'
import { ModalForm, DropFile } from 'eureka-design'
import ModalCargando from '../../components/ModalCargando/ModalCargando'
import TablaProductos from '../../components/TablaProductos/TablaProductos'
import { useObtenerProductosQuery } from '../../services/producto'
import fotoError from '../../imgs/producto-demo.png'
import { NOM_FORM_PRODUCTO } from './form-producto'
import { obtenerData, obtenerLogo } from '../../js/util'
import fotoProducto from '../../imgs/no-logo.png'
import { useObtenerCategoriasQuery } from '../../services/categoria'
import { adaptarDatos } from '../../js/selector'

const ListaProductos = ({ Push }) => {
    const [mostrandoModalActualizar, verModalActualizar] = useState(false)
    const [mostrandoModalCargando, verModalCargando] = useState(false)
    const [buscarId, fijaBuscarId] = useState(null)
    const [categoriaId, fijaCategoriaId] = useState(null)

    const { data:productos, isLoading } = useObtenerProductosQuery({ id: buscarId, categoria: categoriaId })
    const { data:categorias, isLoading:cargandoCategorias } = useObtenerCategoriasQuery()

    const handlerAgregar = () => {
        Push('form_producto', {
            [NOM_FORM_PRODUCTO]: null
        })
    }

    const handlerEditar = (item) => {
        // console.log(item)
        let foto = obtenerData(item, 'eureka_st_productos_fotos.eureka_cr_multimedia')
        let precio = obtenerData(item, 'eureka_st_productos_precios')

        Push('form_producto', {
            [NOM_FORM_PRODUCTO]: {
                ...item,
                foto_id: foto?.id,
                foto_preview: obtenerLogo(foto, 'producto', fotoProducto),
                precio_id: precio?.id,
                precio: precio,
            },
            titulo: `${item?.nombre}`
        })
    }

    const handlerAbrirModalActualizar = () => {
        verModalActualizar(true)
    }

    const handlerActualizarInventario = () => {
        verModalActualizar(false)
        verModalCargando(true)
    }

    const handlerBuscar = (val) => {
        fijaBuscarId(val.id)
    }

    const handlerCategoria = (item) => {
        fijaCategoriaId(item.id)
    }

    const handlerLimpiarCategoria = () => {
        fijaCategoriaId(null)
    }

    if(isLoading || cargandoCategorias) return
    else {
        return (
            <>
                <ModalForm
                    show={mostrandoModalActualizar}
                    titulo="Actualizar inventario"
                    onClose={() => {
                        verModalActualizar(false)
                    }}
                    botones={[
                        { texto: 'Actualizar inventario', onClick: handlerActualizarInventario }
                    ]}
                >
                    <p><b>Sube el archivo con tus productos para poder actualizar tu inventario de manera masiva</b></p>
                    <br />
                    <DropFile />
                </ModalForm>

                <ModalCargando 
                    mostrar={mostrandoModalCargando} 
                    modo="normal"
                    titulo="Actualizando inventario"
                    texto="Espera un momento..."
                />

                <TablaProductos 
                    noDataMsj="Aún no hay productos para mostrar"
                    tipo="Kg."
                    fotoError={fotoError}
                    data={productos}
                    configCategorias={{
                        data: categorias ?? [],
                        propValor: 'nombre',
                        propHijos: 'eureka_st_niveles_rutas_hijos',
                        onClick: handlerCategoria,
                        onClickLimpiarFiltro: handlerLimpiarCategoria,
                    }}
                    buquedad={{ 
                        nombre: 'id',
                        icono: 'fa-magnifying-glass',
                        opciones: adaptarDatos(productos, 'nombre', 'id'),
                        value: null,
                        placeholder: 'Buscar producto...',
                        onChange: handlerBuscar
                    }}
                    botonesHeader={[
                        { texto: 'Actualizar inventario', icono: 'fa-rotate', onClick: handlerAbrirModalActualizar },
                        { tooltip: 'Agregar', icono: 'fa-plus', onClick: handlerAgregar },
                    ]}
                    onClickCard={handlerEditar}
                />
            </>
        )
    }
}

export default ListaProductos