import { useEffect, useState } from 'react'
import { Formulario, ImageInput, Input, Select } from 'eureka-design'
import InputCount from '../../components/InputCount/InputCount'
import { TABLA_PRODUCTO_FOTO, useCrearPrecioProductoMutation, useCrearProductoMutation } from '../../services/producto'
import { useCrearMultimediaMutation, useRelacionarMultimediaMutation } from '../../services/multimedia'
import { useObtenerCategoriasQuery } from '../../services/categoria'
import { useObtenerMarcasQuery } from '../../services/marca'
import { adaptarDatos, obtenerValor } from '../../js/selector'

export const NOM_FORM_PRODUCTO = 'producto'

const FormProducto = ({ producto, titulo, UpTittle, UpProps }) => {
    const { data:categorias, isLoading:cargandoCategorias } = useObtenerCategoriasQuery()
    const { data:marcas, isLoading:cargandoMarcas } = useObtenerMarcasQuery()

    const [crearMultimedia, { isLoading:actFoto, isError:fotoError, isSuccess: fotoCorrecta }] = useCrearMultimediaMutation()
    const [crearProducto, { isLoading:actProducto, isError:productoError, isSuccess:productoCorrecto }] = useCrearProductoMutation()
    const [crearPrecio, { isLoading:actPrecio, isError:precioError, isSuccess:precioCorrecto }] = useCrearPrecioProductoMutation()

    const [relacionarMultimedia] = useRelacionarMultimediaMutation()

    const [productoId, fijaProductoId] = useState(producto?.id)
    const [precioId, fijaPrecioId] = useState(producto?.precio_id)
    const [fotoId, fijaFotoId] = useState(producto?.foto_id)

    useEffect(() => {
        UpTittle(titulo ?? 'Agregar producto')
    })

    const handlerRelacionarFoto = (productoID, fotoID) => {
        relacionarMultimedia({
            tabla: TABLA_PRODUCTO_FOTO,
            body: {
                producto: productoID,
                foto: fotoID,
            }
        })
    }

    const handlerFoto = async (e) => {
        let productoID = productoId ? productoId : await handlerProducto('nombre', '')
        let archivo = e.target.files[0]

        handlerProps('foto_archivo', archivo)

        return await crearMultimedia({
            media: {
                id: fotoId,
                file: archivo,
                carpeta: 'producto',
                col: 'nombre',
            }
        })
        .unwrap()
        .then((res) => {
            let fotoID = res?.mensaje?.id

            if(fotoID) {
                fijaFotoId(fotoID)
                handlerRelacionarFoto(productoID, fotoID)
                return fotoID
            }
            else return fotoId
        })
    }

    const handlerProducto = async (nom, val) => {
        return await crearProducto({
            id: productoId,
            body: {
                tipo: 'kg.',
                ecommerce: 'online',
                [nom]: val,
            }
        })
        .unwrap()
        .then((res) => {
            let id = res?.mensaje?.id
            if(id) {
                fijaProductoId(id)
                return id
            }
            else return productoId
        })
    }

    const handlerPrecio = async (nom, val) => {
        let productoID = productoId ? productoId : await handlerProducto('nombre', '')

        return crearPrecio({
            id: precioId,
            body: {
                producto: productoID,
                moneda: 'mxn',
                tipo: 'regular',
                [nom]: val,
            }
        })
        .unwrap()
        .then((res) => {
            let precioID = res?.mensaje?.id

            if(precioID) {
                fijaPrecioId(precioID)
                return precioID
            }
            else return precioId
        })
    }

    const handlerInput = (val, nom) => {
        if(nom === 'numero') handlerPrecio(nom, val)
        else handlerProducto(nom, val)

        handlerProps(nom, val)
    }

    const handlerSelect = (val, nom) => {
        val = val.id
        handlerProducto(nom, val)
        handlerProps(nom, val)
    }

    const handlerProps = (nom, val) => {
        UpProps({
            [NOM_FORM_PRODUCTO]: {
                ...producto,
                id: productoId,
                precio_id: precioId,
                foto_id: fotoId,
                [nom]: val,
            }
        })
    }

    if(cargandoCategorias || cargandoMarcas) return
    else {
        return (
            <Formulario
                isLoading={[actProducto, actFoto, actPrecio]}
                isError={[productoError, fotoError, precioError]}
                isSuccess={[productoCorrecto, fotoCorrecta, precioCorrecto]}
            >
                <div style={{ display: 'flex', gap: '24px '}}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', justifyContent: 'space-between' }}>
                        <div style={{ flexGrow: 1 }}>
                            <ImageInput 
                                width={250} 
                                height={250}
                                preview={producto?.foto_preview}
                                changeFunction={handlerFoto}
                            />
                        </div>
                        
                        <InputCount 
                            label="Existencia"
                            name="numero"
                            value={producto?.precio?.numero}
                            min={0}
                            max={99}
                            onChange={(nom, val) => { handlerInput(val, nom) }}
                        />
                    </div>
                    <div style={{ flexGrow: 3, display: 'flex', flexDirection: 'column', gap: '16px' }}>
                        <Input 
                            nombre="nombre"
                            placeholder="Nombre del producto"
                            value={producto?.nombre}
                            changeFunction={handlerInput}
                            isLoading={actProducto}
                        />
                        <Input 
                            nombre="codigo"
                            placeholder="Código del producto"
                            value={producto?.codigo}
                            changeFunction={handlerInput}
                            isLoading={actProducto}
                        />
                        <Input 
                            nombre="costo"
                            placeholder="Costo por Kg"
                            value={producto?.costo}
                            changeFunction={handlerInput}
                            isLoading={actProducto}
                        />
                        <Select
                            nombre="nivel" 
                            placeholder="Categoría"
                            value={obtenerValor(
                                categorias,
                                'nombre',
                                'id',
                                producto?.nivel,
                                'Selecciona una categoría...'
                            )}
                            changeFunction={handlerSelect}
                            isLoading={actProducto}
                            options={adaptarDatos(categorias, 'nombre', 'id')}
                        />
                        <Select 
                            nombre="medida"
                            placeholder="Marca"
                            value={obtenerValor(
                                marcas,
                                'select_nombre',
                                'id',
                                producto?.eureka_eui_select?.id ?? producto?.medida,
                                'Selecciona una marca...'
                            )}
                            changeFunction={handlerSelect}
                            isLoading={actProducto}
                            options={adaptarDatos(marcas, 'select_nombre', 'id')}
                        />
                        <Input 
                            nombre="descripcion"
                            type="textarea"
                            placeholder="Descripción"
                            value={producto?.descripcion}
                            changeFunction={handlerInput}
                            isLoading={actProducto}
                        />
                    </div>
                </div>
            </Formulario>
        )
    }
}

export default FormProducto