import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../config/config'
import { EkQuery } from './query'

const TABLA_REMISION = 'EurekaStTransaccione'

export const remisionPublicaAPI = createApi({
    reducerPath: 'remisionPublicaAPI',
    baseQuery: fetchBaseQuery({ baseUrl: config.urlCRUFDEK_V1 }),
    tagTypes: ['RemisionesPublicas'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        obtenerRemision: builder.query({
            /**
             * @param {{ id: number }} data 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_REMISION).where('id', data?.id)

                return {
                    url: `/READ?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body.with('eureka_st_transacciones_conceptos')
                              .with('eureka_st_transacciones_detalles')
                              .with('eureka_ac_usuario')
                              .with('eureka_bs_cliente')
                              .with('eureka_fa_factura')
                              .with('eureka_en_guia')
                              .first()
                }
            },
        }),
    })
})


export const { 
    useObtenerRemisionQuery,
} = remisionPublicaAPI