import { MiPerfil } from 'eureka-design'
import nouser from '../../imgs/nouser.jpg'

const Perfil = ({ usuario }) => {
    console.log(usuario)
    return (
        <MiPerfil 
            avatar={nouser}
            nombre={usuario?.nombre ?? 'Sin Nombre'}
            correo={usuario?.correo ?? 'Sin Correo'}
            telefono={usuario?.telefono ?? 'Sin Teléfono'}
            botones={[
                { iconLeft: 'fa-pen', texto: 'Editar Perfil', onClick: () => { window.location.href = '/cerrar_sesion' } },
                { iconLeft: 'fa-right-from-bracket', texto: 'Cerrar Sesión', onClick: () => { window.location.href = '/cerrar_sesion' } }
            ]}
        />
    )
}

export default Perfil