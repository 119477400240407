export const TIPO_CORRECTO = 'success'
export const TIPO_ERROR = 'error'
export const TIPO_PELIGRO = 'warning'

export const mostrarAlerta = (callback = () => {}, titulo = '', mensaje = '', icono = TIPO_CORRECTO, tiempo = 3) => {
    callback({
        icono: icono,
        titulo: titulo,
        mensaje: mensaje,
        time: tiempo,
    })
}