import CardDetalle from "../../components/CardDetalle/CardDetalle"
import ModalDetalles from "../../components/ModalDetalles/ModalDetalles"
import SeccionInformacion from "../../components/SeccionInformacion/SeccionInformacion"
import { MESES, numberFormat, obtenerDireccion } from "../../js/util"
import mapa from "../../imgs/mapa-demo.png"
import { useEffect, useMemo, useState } from "react"
import { useObtenerRemisionesClienteQuery } from "../../services/remision"
import { calcularTotal, PAGADA, PENDIENTE_PAGO, SIN_PAGAR } from "../../js/remision"

const estiloSubTitulo = {
    fontSize: '20px',
    fontWeight: '600',
    marginBottom: '16px',
}

const estiloDisplayFlex = {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '16px'
}

const InfoCliente = ({ mostrar = true, onClose = () => {}, cliente = null }) => {
    const form_cliente = useMemo(() => cliente, [cliente])

    const { data:remisiones, isLoading } = useObtenerRemisionesClienteQuery({ clienteId: form_cliente?.id })

    const [fecha] = useState(new Date())
    const [totalMes, fijaTotalMes] = useState(0)
    const [total, fijaTotal] = useState(0)

    useEffect(() => {
        if(!isLoading) {
            fijaTotalMes(calcularTotal(remisiones, fecha))
            fijaTotal(calcularTotal(remisiones))
        }
        // else esperar información
    }, [fecha, isLoading, remisiones])

    if(isLoading) return
    else {
        return (
            <ModalDetalles
                mostrar={mostrar}
                onClose={onClose}
                titulo="Detalle cliente"
            >
                <SeccionInformacion
                    usuario={cliente}
                    botones={[
                        { texto: 'Editar', icon: 'fa-pen', onClick: () => {} },
                        { texto: 'Eliminar', icon: 'fa-trash-can', onClick: () => {} },
                    ]}
                >
                    <h2 style={estiloSubTitulo}>Dirección</h2>
                    <p style={{ fontSize: '16px' }}>
                        {obtenerDireccion(form_cliente?.domicilio)}
                    </p>
                    <img style={{ width: '488px', height: 'auto' }} src={mapa} alt="mapa" />

                    <h2 style={estiloSubTitulo}>{remisiones?.length} pedidos realizados</h2>
                    <div style={estiloDisplayFlex}>
                        <CardDetalle 
                            titulo={MESES[fecha.getMonth()]}
                            texto={numberFormat(totalMes)}
                            mostrarIcono={false}
                        />
                        <CardDetalle 
                            titulo="Total"
                            texto={numberFormat(total)}
                            mostrarIcono={false}
                        />
                    </div>

                    <h2 style={estiloSubTitulo}>{remisiones?.length} remisiones</h2>
                    <div style={estiloDisplayFlex}>
                        <CardDetalle 
                            titulo="Sin pagar"
                            texto={remisiones?.filter((remision) => remision.cxp_cxc === SIN_PAGAR).length}
                            icono="fa-triangle-exclamation"
                            color="#EF1F1F"
                        />
                        <CardDetalle 
                            titulo="Pendientes"
                            texto={remisiones?.filter((remision) => remision.cxp_cxc === PENDIENTE_PAGO).length}
                            icono="fa-triangle-exclamation"
                            color="#FFB800"
                        />
                        <CardDetalle 
                            titulo="Pagadas"
                            texto={remisiones?.filter((remision) => remision.cxp_cxc === PAGADA).length}
                            icono="fa-circle-check"
                            color="#26BE19"
                        />
                    </div>
                </SeccionInformacion>
            </ModalDetalles>
        )
    }
}

export default InfoCliente