import { useMemo } from "react"
import InformacionVenta from "../../components/InformacionVenta/InformacionVenta"
import ModalDetalles from "../../components/ModalDetalles/ModalDetalles"

const DetalleRemision = ({ 
    remision = null,
    mostrar = true,
    onClose = () => {},
    modoVista = false,
}) => {
    const form_remision = useMemo(() => remision, [remision])

    return (
        <ModalDetalles
            mostrar={mostrar}
            onClose={onClose}
            titulo="Detalle remisión"
            modoVista={modoVista}
        >
            <InformacionVenta 
                modoVista={modoVista}
                venta={form_remision}
                conceptos={form_remision?.eureka_st_transacciones_conceptos}
            />
        </ModalDetalles>
    )
}

export default DetalleRemision