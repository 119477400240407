import './ModalDetalles.css'

const ModalDetalles = ({
    mostrar = true,
    posicion = 'der',
    onClose = () => {},
    titulo = 'Sin titulo',
    modoVista = false,
    children
}) => {
    return (
        <div 
            className={`
                fondo-modal-detalles 
                ${mostrar ? 'fondo-modal-detalles--mostrar' : ''}
                ${modoVista ? 'fondo-modal-detalles--vista' : ''}
            `} 
            onClick={(e) => {
                if(onClose) onClose() 
                else return
            }}
        >
            <div 
                className={`
                    modal-detalles 
                    modal-detalles--${posicion} 
                    ${modoVista ? 'modal-detalles--vista' : ''}
                    ${mostrar ? 'modal-detalles--mostrar' : ''}
                `}
                onClick={(e) => { e.stopPropagation() }}
            >
                <div className="modal-detalles__cabezera">
                    <p className="modal-detalles__cabezera__titulo">{titulo}</p>
                    {
                        onClose ? 
                            <i className="fa-solid fa-xmark modal-detalles__cabezera__icono" onClick={onClose}></i> : undefined
                    }
                </div>
                {children}
            </div>
        </div>
    )
}

export default ModalDetalles