import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../config/config'
import { EkQuery } from './query'
import { token } from 'account-react-eurekasigma'

const TABLA_CREDITO = 'EurekaPaBilletera'
const TABLA_ABONO = 'EurekaStTransaccionesDetalle'

export const creditoAPI = createApi({
    reducerPath: 'creditoAPI',
    baseQuery: fetchBaseQuery({ baseUrl: config.urlCRUFDEK }),
    tagTypes: ['Creditos'],
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        crearCredito: builder.mutation({
            /**
             * @param {{ id: number, body: object }} data 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_CREDITO)
                body = (data?.id) ? body.where('id', data.id).update(data.body) : body.create(data.body)

                return {
                    url: `/CUFD?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        crearAbono: builder.mutation({
            /**
             * @param {{ id: number, body: object }} data 
             */
            query: (data) => {
                let body = new EkQuery(TABLA_ABONO)
                body = (data?.id) ? body.where('id', data.id).update(data.body) : body.create(data.body)

                return {
                    url: `/CUFD?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body
                }
            }
        }),
        obtenerCredito: builder.mutation({
            /**
             * @param {{ id:number, col:string }} data 
             * @returns
             */
            query: (data) => {
                let body = new EkQuery(TABLA_CREDITO)
                body = (data?.id) ? body.where(data?.col ?? 'id', data.id) : body.where('id', 'null')

                return {
                    url: `/READ?key=${config.keyBD}&type=API`,
                    method: 'POST',
                    headers: {
                        'Authorization' : `Bearer ${token()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: body.first()
                }
            }
        }),
    }),
})

export const { 
    useCrearCreditoMutation,
    useCrearAbonoMutation,
    useObtenerCreditoMutation,
} = creditoAPI