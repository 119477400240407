import './CardProducto.css'
import sinFoto from '../../../imgs/no-logo.png'
import React, { useMemo } from 'react'

const CardProducto = React.memo(({
    foto = null,
    nombre = '',
    id = '',
    precio = '',
    inventario = 0,
    onClick = () => {},
}) => {
    const card = useMemo(() => {
        return (
            <div className="card-producto" onClick={onClick}>
                <img className="card-producto__foto" src={foto ?? sinFoto} alt="foto producto" />
                <div className="card-producto__informacion">
                    <p className="card-producto__informacion__titulo">{nombre}</p>
                    <p className="card-producto__informacion__extra">ID: {id}</p>
                    <p className="card-producto__informacion__extra">{precio}</p>
                    <p className="card-producto__informacion__extra">
                        <span>{inventario}</span> piezas disponibles
                    </p>
                </div>
            </div>
        )
    }, [foto, nombre, id, precio, inventario, onClick])
    
    return card
})

export default CardProducto