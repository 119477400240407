import { Tabla, buildData, ChipEstatus, Miniatura } from 'eureka-design'
import { ESTATUS_ACTIVO, ESTATUS_INACTIVO, obtenerData, obtenerLogo } from '../../js/util'
import { useEffect, useState } from 'react'
import FormVendedor, { NOM_FORM_VENDEDOR } from '../vendedores/form-vendedor'
import { useCrearVendedorMutation, useObtenerVendedoresQuery } from '../../services/vendedor'

const estiloDivNombre = { 
    display: 'flex', 
    alignItems: 'center',
    gap: '5px'
}

const estiloSpanNombre = {
    width: '80%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
}

const ListaVendedores = ({ ShowAlert, Push, UpProps, form_vendedor }) => {
    const { data, isLoading, refetch } = useObtenerVendedoresQuery()

    const [crearVendedor] = useCrearVendedorMutation()

    const [vendedor, fijaVendedor] = useState(form_vendedor)
    const [mostrarModal, cambiarEstadoModal] = useState(false)

    useEffect(() => {
        fijaVendedor(form_vendedor)
    }, [form_vendedor])

    const handlerRegistrar = () => {
        UpProps({ [NOM_FORM_VENDEDOR]: null })
        fijaVendedor(null)
        cambiarEstadoModal(true)
    }

    const handlerEditar = (item) => {
        let vendedor = item.item
        let domicilio = obtenerData(vendedor, 'eureka_in_domicilios')
        let foto = obtenerLogo({ carpeta: 'vendedor', nombre: vendedor?.foto })

        // console.log(vendedor)
        fijaVendedor({
            ...vendedor,
            domicilio_id: domicilio.id,
            domicilio: domicilio,
            foto: foto,
        })
        cambiarEstadoModal(true)
    }

    const handlerArchivar = (item) => {
        let vendedor = item.item
        let estatus = ''
        let mensaje = ''

        if(vendedor?.estatus === ESTATUS_ACTIVO) {
            estatus = ESTATUS_INACTIVO
            mensaje = 'El vendedor a pasado a inactivo'
        }
        else {
            estatus = ESTATUS_ACTIVO
            mensaje = 'El vendedor a pasado a activo'
        }

        crearVendedor({
            id: vendedor.id,
            body: { estatus: estatus }
        })
        .unwrap()
        .then(() => {
            ShowAlert({
                icono: 'success',
                titulo: 'Estatus Actualizado',
                mensaje: mensaje,
                time: 3,
            })
            refetch()
        })
    }

    const handlerVerRemisiones = (item) => {
        let vendedor = item.item
        Push('lista_remisiones_usuario', {
            usuario: vendedor
        })
    }

    if(isLoading) return
    else {
        return (
            <>
                <FormVendedor 
                    vendedor={vendedor}
                    mostrar={mostrarModal}
                    handlerMostrar={cambiarEstadoModal}
                    UpProps={UpProps}
                    actualizarVendedores={refetch}
                />

                <Tabla
                    checkbox={false}
                    rowSize="medium"
                    noData="Aún no hay vendedores registrados"
                    busqueda={{ placeholder: "Buscar cliente", keys: ["nombre"] }}
                    keys={[
                        {
                            icono: 'fa-users',
                            text: "Todos",
                            detalle: data.length,
                            filtro: () => true
                        },
                        {
                            icono: 'fa-circle-check',
                            text: "Activos",
                            detalle: data.filter(d => d.estatus === 'activo').length,
                            filtro: item => item.item.estatus === "activo"
                        },
                        {
                            icono: 'fa-user-slash',
                            text: "Archivados",
                            detalle: data.filter(d => d.estatus === 'inactivo').length,
                            filtro: item => item.item.estatus === "inactivo"
                        },
                    ]}
                    headers={[
                        { key: 'nombre',    text: 'Nombre',             type: 'text' },
                        { key: 'clientes',  text: 'Clientes',           type: 'text' },
                        { key: 'correo',    text: 'Correo eléctronico', type: 'text' },
                        { key: 'telefono',  text: 'Teléfono',           type: 'text' },
                        { key: 'municipio', text: 'Municipio',          type: 'text' },
                        { key: 'estatus',   text: 'Estado',             type: 'text' },
                    ]}
                    data={buildData(data, [
                        { 
                            key: 'nombre',
                            type: 'text',
                            text: (item) => {
                                let foto = (item?.foto?.length > 0) ? { carpeta: 'vendedor', nombre: item?.foto } : null
                                return (
                                    <div style={estiloDivNombre}>
                                        <div style={{ width: '30px' }}>
                                            <Miniatura avatar={true} src={obtenerLogo(foto)} />
                                        </div>
                                        <span style={estiloSpanNombre}>{item.nombre}</span>
                                    </div>
                                )
                            }
                        },
                        { 
                            key: 'clientes',
                            type: 'text',
                            text: (item) => {
                                return '20 clientes'
                            }
                        },
                        { key: 'correo',    type: 'text', text: (item) => item?.correo ?? 'Sin correo' },
                        { key: 'telefono',  type: 'text', text: (item) => item?.telefono ?? 'Sin teléfono' },
                        { 
                            key: 'municipio', 
                            type: 'text',
                            text: (item) => {
                                let domicilio = obtenerData(item, 'eureka_in_domicilios')
                                return domicilio.nombre ?? 'N/A'
                            }
                        },
                        { 
                            key: 'estatus',
                            type: 'text',
                            text: (item) => {
                                let texto, tipo
                                if(item.estatus === ESTATUS_ACTIVO) {
                                    texto = 'Activo'
                                    tipo = 'Aceptado'
                                }
                                else {
                                    texto = 'Suspendido'
                                    tipo = 'Error'
                                }
                                return <ChipEstatus texto={texto} tipo={tipo} />
                            }
                        },
                    ])}
                    botonesHead={[
                        { icono: 'fa-plus', tooltip: 'Agregar', onClick: handlerRegistrar },
                    ]}
                    botonesRow={[
                        { icono: 'fa-file-invoice-dollar', tooltip: 'Ver remisiones', onClick: handlerVerRemisiones },
                        { icono: 'fa-pen', tooltip: 'Editar', onClick: handlerEditar },
                        { icono: 'fa-trash', tooltip: 'Suspender', onClick: handlerArchivar },
                    ]}
                />
            </>
        )
    }
}

export default ListaVendedores